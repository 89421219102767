import styled, { css } from "styled-components/macro";
import Icon from "./Icon";
import LoadDiv from './LoadDiv';
import classnames from "classnames";

const S = {
	Option: styled.div`
		width: 100%;

		padding: 9px 12px;
		
		cursor: pointer;
		position: relative;
		background-color: var(--color-grey-mid);
		
		&:hover {
			background-color: var(--color-selection);
		}

		&.selected {
			background-color: var(--color-cta);
			fill: #fff;
			color: #fff;
		}

		&.disabled {
			opacity: 0.5;
		}

		${({theme}) => {
			switch(theme){
				case "simple": return css`
					background-color: transparent;
				`;
				default: return "";
			}
		}}
	`,
	DescriptionContainer: styled.div`

		${({hasIcon}) => `
			padding: 0px 0px 6px ${hasIcon ? 30 : 0}px;
		`}

		p {
			margin: 0px;
		}
	`
}

const StandardDropdownOption = ({
	icon,
	label,
	disabled,
	onClick,
	disabledHint,
	onMouseDown,
	selected,
	isDefault,
	description,
	children,
	loading,
	theme
}) => {

	return <S.Option
		className={classnames({
			selected: selected && !isDefault,
			"with-description": !!description,
			"is-default": isDefault,
		})}
		theme={theme}
		disabled={disabled}
		disabledHint={disabledHint}
		onMouseDown={evt => {
			if(onClick){
				onClick(evt);
			}
			if(onMouseDown){
				onMouseDown(evt)
			}
		}}
	>
		<div className="flex-container-centered">
			{
				icon ? <>
					<Icon icon={icon} />
					<div className="space-12" />
				</> : null
			}
			{
				label && typeof(label) === "string" ? <p className="margin-0 nowrap">{label}</p> : label
			}
		</div>
		{
			description && !isDefault ? <S.DescriptionContainer hasIcon={!!icon}>
				{
					typeof(description) === "string" ? <p className="margin-0">{description}</p> : description
				}
			</S.DescriptionContainer> : null
		}
		{ children }
		{
			loading ? <LoadDiv expand open /> : null
		}
	</S.Option>
}

export default StandardDropdownOption;