import { useEffect } from "react";

const useInterval = (callback, interval) => {

	useEffect(() => {
		const i = setInterval(callback, interval);
		return () => clearInterval(i);
	}, [callback, interval]);

}

export default useInterval;