import Dropdown from "./Dropdown";
import CardDropdownItem from "./CardDropdownItem";

const CardDropdown = (props) => {
	const { onChange, closeOnClick=true } = props;

	return <Dropdown
		{...props}
	>
		{
			(card, index, { onClose, isHighlighted }) => <CardDropdownItem
				key={card?.id}
				card={card}
				isHighlighted={isHighlighted}
				onClick={evt => {
					if(closeOnClick){
						evt.preventDefault();
						evt.stopPropagation();
					}
					if(onChange){
						onChange(card);
					}
					if(closeOnClick){
						onClose(evt);
					}
				}}
			/>
		}
	</Dropdown>

}

export default CardDropdown;