import ReactDOM from "react-dom";

const usePortal = (id="korshem-portals") => {

	const inject = element => {
		const portal = document.getElementById(id);
		if(!portal){
			console.log(`usePortal with id ${id} couldn't find injection site -- make sure to add it to public.html or something.`);
			return null
		} else {
			return ReactDOM.createPortal(element, portal);
		}
	}

	return inject;
}

export default usePortal;