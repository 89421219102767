

const formats = {
	cc: {
		id: "cc",
		label: "Classic Constructed",
		icon: "format-cc",
		standardCardLimit: 3,
		total: 80,
		equipmentTotal: -1,
	},
	blitz: {
		id: "blitz",
		label: "Blitz",
		icon: "format-blitz",
		standardCardLimit: 2,
		total: 40,
		equipmentTotal: 10,
	}
};

export default formats;