import React, { Fragment, useMemo, useState, useCallback } from "react";
import Popup from "./Popup";
import Icon from './Icon';
import styled from "styled-components/macro";
import { Scrollbars } from "react-custom-scrollbars";
import useKey from "./../hooks/useKey";

const S = {
	TitleContainer: styled.div`
		height: 24px;
		padding: 6px;
		background-color: var(--color-grey-dark);
		display: flex;
		align-items: center;

		p {
			margin: 0px;
			flex: 1;
			font-size: var(--font-size-small);
			line-height: var(--line-height-small);
			font-weight: var(--font-weight-medium);
			color: var(--color-text);
		}
	`,
	TitleIcon: styled(Icon)`
		fill: var(--color-text);
	`
}

const DropdownList = props => {
	const defaultSides = useMemo(() => [ "bottom", "top" ], []);
	const { open, options, onChange, children, title, onClose, titleIcon, minHeight=0, maxHeight=250 } = props;

	const [ highlightedIndex, setHighlightedIndex ] = useState(-1);

	useKey(open && "ArrowUp", useCallback(() => setHighlightedIndex(s => s - 1), []));
	useKey(open && "ArrowDown", useCallback(() => setHighlightedIndex(s => s + 1), []));
	useKey(
		open
			&& onChange
			&& highlightedIndex >=0 && highlightedIndex < options.length
			&& "Enter",
		useCallback(() => {
			onChange(options[highlightedIndex])
			if(onClose){
				onClose();	
			}
		}, [ onClose, highlightedIndex, options, onChange ])
	);
	useKey(open && onClose && "Escape", onClose);

	return (
		<Popup
			sides={ defaultSides }
			{...props}
			pointerEvents
		>
			{
				title ? <S.TitleContainer>
					<p>{title}</p>
					{
						titleIcon ? <S.TitleIcon icon={titleIcon} size={12} /> : null
					}
				</S.TitleContainer> : null
			}

			<Scrollbars autoHeight autoHeightMin={minHeight} autoHeightMax={maxHeight} hideTracksWhenNotNeeded>
				{
					options ? options.map((op, i) => <Fragment key={i}>
						{ children(op, i, { onClose, isHighlighted: highlightedIndex === i }) }
					</Fragment>) : null
				}
				{
					!options && children ? React.Children.map(children, (child, i) => <Fragment key={i}>
						{ child }
					</Fragment>) : null
				}
			</Scrollbars>
		</Popup>
	)
}

export default DropdownList
