import styled from "styled-components/macro";
import Icon from "./Icon";

const S = {
	Container: styled.div`
		display: flex;
		background-color: var(--color-selection);

		padding: 12px;

		// STYLES

		&.error {
			background-color: var(--color-warn-light);
		}

	`,
	Icon: styled(Icon)`
		margin: 6px 12px 6px 6px;
	`
}

const Callout = ({className, children, onDismiss, icon}) => {

	return <S.Container className={className}>
		{
			icon ? <S.Icon className="no-shrink" icon={icon} /> : null
		}
		<div className="flex overflow-hidden">
			{ children }
		</div>
		{
			onDismiss ? <Icon onClick={onDismiss} className="margin-6 auto-opacity" icon="remove" /> : null
		}
	</S.Container>
}

export default Callout;