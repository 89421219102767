import { useState } from "react";
import Draggable from "./Draggable";
import styled from "styled-components/macro";
import classnames from "classnames";

const S = {
	Container: styled.div`
		position: relative;
		pointer-events: auto;

		&.dimension-x {
			height: 100%;
		}

		&.dimension-y {
			width: 100%;
		}

	`,
	Handle: styled(Draggable)`
		position: absolute;
		background-color: var(--color-cta-50);
		border-radius: 1px;
		opacity: 0;
		transition: opacity 300ms;

		:hover {
			opacity: 1;
		}

		.dimension-x & {
			cursor: col-resize;
			width: 6px;
			top: 0px;
			left: 50%;
			transform: translateX(-50%);
			margin: 2px 0px;
			height: 100%;
		}

		.dimension-y & {
			cursor: row-resize;
			height: 6px;
			top: 50%;
			left: 0px;
			transform: translateY(-50%);
			margin: 0px 2px;
			width: 100%;
		}
	`
}


const ResizeHandle = ({className, dimension="x", value, onChange, style, padding=12, onRelease, disabled}) => {

	const [ dragStart, setDragStart ] = useState(0);
	const [ dragStartAmount, setDragStartAmount ] = useState(value);

	return <S.Container
		className={classnames(className, `dimension-${dimension}`)}
		style={style}
	>
		<S.Handle
			disabled={disabled}
			onDragBegin={(data) => {
				// evt.preventDefault();
				// evt.stopPropagation();
				if (document.selection) {
					document.selection.empty()
				} else {
					window.getSelection().removeAllRanges()
				}
				const v = data[dimension]
				setDragStart(v)
				setDragStartAmount(value)
			}}
			onDrag={(data, evt) => {
				evt.preventDefault();
				evt.stopPropagation();
				if (document.selection) {
					document.selection.empty()
				} else {
					window.getSelection().removeAllRanges()
				}
				const v = data[dimension]
				const newAmount = dragStartAmount + (dragStart - v);

				if(Math.abs(newAmount - value) >= 1){
					onChange(newAmount)
				}
			}}
			onDragEnd={(data, evt) => {
				if(onRelease){
					onRelease();
				}
			}}
		 />
	</S.Container>
}

export default ResizeHandle