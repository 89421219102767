import styled, { css } from "styled-components/macro";
import Icon from "./Icon";
import classnames from "classnames";
import { forwardRef, useState, useEffect, useRef } from "react";

const S = {
	Container: styled.div`

		position: relative;
		display: flex;
		align-items: center;
		border-radius: 4px;
		overflow: hidden;
		background-color: rgba(0, 0, 0, 0.2);
		border: 1px solid var(--color-grey-light);
		fill: var(--color-text);

		input {
			background-color: transparent;
			padding: 6px 12px;
			width: 100%;
			font-weight: var(--font-weight-semibold);
		}

		&.simple {

			border: none;
			background-color: transparent;
			transition: background-color 200ms;

			&:hover {
				background-color: rgba(0, 0, 0, 0.4);
			}

			input {
				padding: 3px;
				text-align: center;
			}
		}

		&.medium input {
			font-size: var(--font-size-medium);
		}

		&.h3 {
			font-size: 20px;
			font-weight: var(--font-weight-semibold);
		}

		&.h2 input {
			font-size: 22px;
			font-weight: var(--font-weight-bold);	
			color: white;
		}

		&.align-left input {
			text-align: left;
		}

	`,
	Autocomplete: styled.input`
		position: absolute;
		top: 0px;
		left: 0px;
		width: 100%;
		height: 100%;
		pointer-events: none;
		opacity: 0.5;
	`,
	Placeholder: styled.p`
		position: absolute;
		top: 50%;
		left: 12px;
		right: 12px;
		transform: translateY(-50%);
		margin: 0px;
		pointer-events: none;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		color: var(--color-text-light);
		font-style: italic;

		${({disabled}) => disabled && css`
			display: none;
		`}

		.simple & {
			text-align: center;
			left: 3px;
			right: 3px;
		}

		.medium & {
			font-size: var(--font-size-medium);
		}

		.h3 & {
			font-size: 20px;
			font-weight: var(--font-weight-semibold);
		}

		.h2 & {
			font-size: 22px;
			font-weight: var(--font-weight-bold);
		}

		.align-left & {
			text-align: left;
		}
	`
}

const allowNonemptySubmit = val => typeof(val) === "number" || val.length > 0;

export const StatefulInput = props => {
	const [ value, setValue ] = useState(props.value);
	useEffect(() => {
		setValue(props.value)
	}, [ props.value ]);
	return <Input
		{...props}
		value={value}
		onChange={setValue}
		onSubmit={() => props.onSubmit(value)}
	/>
}

const Input = forwardRef(({
	placeholder,
	value,
	onChange,
	className,
	onKeyDown,
	onBlur,
	onFocus,
	disabled,
	onSubmit,
	icon,
	allowSubmit=allowNonemptySubmit,
	blurBehaviour,
	onIconClick,
	iconSide="right",
	focus,
	select,
	type,
	step,
	onTab,
	onTabPrevious,
	onWheel,
	autoComplete,
}, ref) => {

	const internalRef = useRef();

	useEffect(() => {
		if(focus){
			internalRef.current.focus();
		}
	}, [ focus ])

	useEffect(() => {
		if(select){
			internalRef.current.select();
		}
	}, [ select ])

	const renderIcon = () => <Icon onClick={onIconClick} className={!!onIconClick ? "auto-opacity" : null} icon={icon} />

	return <S.Container className={classnames(className, { disabled })}>
		{
			icon && iconSide === "left" ? <>
				<div className="space-12" />
				{ renderIcon() }
			</> : null
		}
		<div className="flex relative">
			<input
				type={type}
				ref={r => {
					internalRef.current = r;
					if(ref){
						ref.current = r;	
					}
				}}
				disabled={disabled}
				value={value == null || value === undefined ? "" : value}
				onBlur={evt => {
					switch(blurBehaviour){
						case "submit":
							if(onSubmit){
								onSubmit(value)
							}
						break;
						default:
						break;
					}
					if(onBlur){
						onBlur(evt)	
					}
				}}
				onFocus={onFocus}
				onChange={evt => onChange(evt.target.value)}
				onKeyDown={evt => {
					if(onKeyDown){
						const cancelPropagation = onKeyDown(evt);
						if(cancelPropagation){
							return;
						}
					}
					switch(evt.keyCode){
						case 9: // tab
							if(evt.shiftKey){
								if(onTabPrevious){
									onTabPrevious();
									evt.preventDefault();
									evt.stopPropagation();
								}
							} else {
								if(onTab){
									onTab();
									evt.preventDefault();
									evt.stopPropagation();
								}
							}
						break;
						default:
						break;
					}
					switch(evt.key){
						case "Enter":
							if(
								onSubmit &&
								(
									(typeof(allowSubmit) === "boolean" && allowSubmit) || allowSubmit(value)
								)
							){
								onSubmit(value);
								internalRef.current.blur();
							}
						break;
						case "Escape":
							internalRef.current.blur();
						break;
						default:
						break;
					}

				}}
			/>
			<S.Placeholder
				disabled={typeof(value) === "string" ? value.length !== 0 : (value !== undefined && value !== null) }
			>{
				placeholder
			}</S.Placeholder>
			{
				autoComplete ? <S.Autocomplete
					value={`${value}${autoComplete.substring(value.length)}`}
					readOnly
				/> : null
			}
		</div>
		{
			icon && iconSide === "right" ? <>
				{ renderIcon() }
				<div className="space-12" />
			</> : null
		}
	</S.Container>

})

export default Input;