import { Input, CardImage, StandardDropdown, Icon, CardDropdown, Button } from "./../components";
import { useState, useEffect, useMemo } from "react";
import useAuth from "./../hooks/useAuth";
import styled from "styled-components";
import { useLinkClickHandler, useNavigate } from "react-router-dom";
import { useCard, useDecks, createDeck, useCardSearch } from './../hooks/useDatabase'
import { sortDecks } from "./../utils/DeckUtils";
import { useLoader } from "./../singleton-hooks";
import formats from './../data/formats';

const S = {
	Container: styled.div`
		background-color: var(--color-grey-base);
		padding: 24px;
		border-radius: 2px;
		max-width: 400px;
		margin: 24px;
	`
}

const DeckLink = ({
	deck
}) => {

	const handleClick = useLinkClickHandler(`deck/${deck.id}`)
	const [ hero ] = useCard(deck?.heroId);

	return <div className="flex-container-centered auto-opacity padding-6" onClick={handleClick}>
		<CardImage card={hero} width={42} />
		<div className="space-6" />
		<div>
			<h4 className="margin-0 semibold">{deck.name}</h4>
			<div className="space-6" />
			<div className="flex-container-centered">
				<Icon icon={formats[deck?.format]?.icon} />
				<div className="space-6" />
				<p className="margin-0">{formats[deck?.format]?.label}</p>
			</div>
		</div>
	</div>
}

const Home = () => {
	const [ name, setName ] = useState("");
	const [ format, setFormat ] = useState("cc");
	let [ decks ] = useDecks();
	decks = useMemo(() => sortDecks(decks), [decks]);
	const [ heroes ] = useCardSearch("", format === "blitz" ? "types:hero AND subtypes:young" : "types:hero");
	const [ hero, setHero ] = useState(null);
	const navigate = useNavigate();
	const load = useLoader();

	const user = useAuth();

	useEffect(() => {
		if(heroes?.length){
			setHero(heroes[0]);	
		}
	}, [ heroes ]);

	return <>
	{
		user ? <div className="flex-container">
			<S.Container className="flex">
				<h4>New deck</h4>
				<Input
					value={name}
					onChange={setName}
					placeholder="Deck name..."
				/>
				<div className="space-12" />
				<StandardDropdown
					value={format}
					onChange={setFormat}
					options={formats}
				/>
				<div className="space-12" />
				<CardDropdown
					value={hero}
					onChange={setHero}
					options={heroes}
				/>
				<div className="space-24" />
				<Button
					label="Create"
					className="cta"
					disabled={!name.length || !hero?.id}
					onClick={async () => {
						const deckId = await load(createDeck({name, format, heroId: hero?.id}), {
							title: "Creating deck...",
						});
						navigate(`/deck/${deckId}`)
					}}
				/>
			</S.Container>
			<S.Container className="flex">
				<h4>Your Decks</h4>
				{
					decks?.map((d, i) => <DeckLink deck={d} key={i} />)
				}
			</S.Container>
		</div> : <S.Container>
			<p>You're not signed in :/</p>
		</S.Container>
	}
	</>
}

export default Home;