import { useState, useRef, useEffect } from "react";

const Mounter = ({open, timeout=200, children}) => {

	const prevOpen = useRef(open);
	const [ , rerender ] = useState({});

	const lingerRef = useRef(false);

	if(!open && prevOpen.current){
		lingerRef.current = true;
	}

	prevOpen.current = open;

	useEffect(() => {
		if(!lingerRef.current){
			return
		}
		const t = setTimeout(() => {
			lingerRef.current = false;
			rerender({});
		}, timeout);
		return () => {
			clearTimeout(t);
		}
	}, [open, timeout]);

	if(open || lingerRef.current){
		return children;
	}

	return null

}

export default Mounter;