import styled, { css } from "styled-components";
import Icon from "./Icon";
import CardHint from "./CardHint"
import { pitchColors } from "./../AppStyles";

const S = {
	Container: styled.div`
	`,
	Label: styled.p`
		margin: 0px;
	`,
	ButtonContainer: styled.div`
		cursor: pointer;
		background-color: transparent;
		padding: 6px;

		border-radius: 2px;

		&:hover {
			background-color: var(--color-selection);
		}

		${({isDisabled}) => isDisabled && css`
			pointer-events: none;
			cursor: auto;
			opacity: 0.5;
		`}
	`,
	PitchContainer: styled.div`
		padding: 6px;
	`,
	PitchDisplay: styled.div`
		width: 12px;
		height: 12px;
		border-radius: 6px;
		background-color: ${({backgroundColor}) => backgroundColor};
	`
}

const InlineCard = ({card, onAdd, onRemove, limit, popupProps}) => {
	return <S.Container className="flex-container-centered">
		<div className="flex">
			<CardHint card={card} className="flex-container-centered" popupProps={popupProps}>
				<S.PitchContainer>
					<S.PitchDisplay backgroundColor={card.pitch ? `var(${pitchColors[card.pitch - 1]})` : null} />
				</S.PitchContainer>
				<div className="space-6" />
				<S.Label>{card.name}</S.Label>
			</CardHint>
		</div>
		<div className="space-12" />
		<S.ButtonContainer
			className="padding-6"
			onClick={onRemove}
			isDisabled={!onRemove}
		>
			<Icon icon="remove" />
		</S.ButtonContainer>
		<S.ButtonContainer
			className="padding-6"
			onClick={onAdd}
			isDisabled={!onAdd}
		>
			<Icon icon="add" />
		</S.ButtonContainer>
	</S.Container>
}

export default InlineCard;