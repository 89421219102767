import styled from "styled-components";
import { useState, useEffect, useMemo } from "react";
import { Button, Condition, InlineCard, Input, LoadDiv } from './../components';
import { useCardSearch, setCardTotal } from "./../hooks/useDatabase";
import { Scrollbars } from "react-custom-scrollbars"
import { sortCards, cardTotalValid } from "./../utils/DeckUtils";
import { getAlgoliaQuery } from "./../utils/StatUtils";
// import { emptyCondition } from './../data/conditions';
import formats from "./../data/formats";

const S = {
	Container: styled.div`
		display: flex;
		flex-direction: column;
		overflow: hidden;
	`,
}

const Library = ({className, deck, boards, boardIndex}) => {

	const [ term, setTerm ] = useState("");

	const [ confirmedTerm, setConfirmedTerm ] = useState("");

	const [ condition, setCondition ] = useState(null);

	const algoliaQuery = getAlgoliaQuery(condition);

	const [ cards, loading ] = useCardSearch(confirmedTerm, algoliaQuery);

	const sorted = useMemo(() => sortCards(cards), [ cards ]);

	const format = formats[deck?.format];

	useEffect(() => {
		if(term.length === 0){
			setConfirmedTerm("");
		} else {
			const timeout = setTimeout(() => {
				setConfirmedTerm(term);
			}, 200);
			return () => clearTimeout(timeout);
		}
	}, [ term ]);


	return <S.Container className={className}>
		<div className="space-24" />
		<Input
			placeholder="Search cards..."
			value={term}
			onChange={setTerm}
			onSubmit={() => setConfirmedTerm(term)}
		/>
		<div className="flex-container-centered">
			<Condition condition={condition} onChange={setCondition} />
			<div className="flex" />
			<Button classNAme="small" icon="close" onClick={() => setCondition(null)} />
		</div>
		<div className="space-12" />
		<LoadDiv open={loading} />
		<div className="flex">
			<Scrollbars>
				{
					sorted.map(card => {
						const count = boards[boardIndex]?.cards?.find(c => c.id === card.id)?.count || 0;
						return <InlineCard
							key={card.id}
							card={card}
							popupProps={{
								side: "left",
							}}
							onAdd={
								cardTotalValid(format, card, count + 1) ?
									() => setCardTotal(deck, boards[boardIndex], card.id, count + 1)
									: null
							}
							onRemove={
								cardTotalValid(format, card, count - 1) ?
									() => setCardTotal(deck, boards[boardIndex], card.id, count - 1, { allowGhosting: false })
									: null
							}
						/>
					})
				}
				{
					/*cards.map(c => <Card key={c.id} card={c} />)*/
				}
			</Scrollbars>
		</div>
	</S.Container>

}

export default Library;