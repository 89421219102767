

const BORDER_RATIO = 0.047;
const HEIGHT_RATIO = 1.4;
const BORDER_RADIUS_RATIO = 0.059;
const CARD_IMAGE_HORZ_RATIO = 0.1075949367;
const CARD_IMAGE_TOP_RATIO = 0.1772151899;
const CARD_IMAGE_BOTTOM_RATIO = 0.5632911392;

const CARD_TITLE_RATIO = 0.1772151899;

const SQUARE_HORZ = 0.1898734177;
const SQUARE_TOP = 0.2088607595;

const FRAME_HORZ = BORDER_RATIO;
const FRAME_TOP = 0.1772151899;

export const getCardTitleHeight = width => width * CARD_TITLE_RATIO;
export const getCardHeight = width => width * HEIGHT_RATIO;

export const cardDimensionGetters = {
	full: width => ({
		x: 0,
		y: 0,
		width,
		height: width * HEIGHT_RATIO,
		containerWidth: width,
		containerHeight: width * HEIGHT_RATIO,
		borderRadius: width * BORDER_RADIUS_RATIO,
	}),
	borderless: width => {
		const borderSize = width * BORDER_RATIO;
		return {
			x: -borderSize,
			y: -borderSize,
			containerWidth: width,
			containerHeight: (width * HEIGHT_RATIO - borderSize * 2) / (width - borderSize * 2) * width,
			width: width + borderSize * 2,
			height: (width + borderSize * 2) * HEIGHT_RATIO,
			borderRadius: (width - borderSize * 2) * BORDER_RADIUS_RATIO,
		}
	},
	square: width => {
		const endWidthAmount = 1 - (SQUARE_HORZ * 2);
		const finalWidth = width / endWidthAmount;
		return {
			x: -finalWidth * SQUARE_HORZ,
			y: -finalWidth * SQUARE_TOP,
			containerWidth: width,
			containerHeight: width,
			width: finalWidth,
			height: finalWidth * HEIGHT_RATIO,
			borderRadius: null,
		}
	},
	framed: width => {
		const endWidthAmount = 1 - (FRAME_HORZ * 2);
		const finalWidth = width / endWidthAmount;
		return {
			x: -finalWidth * FRAME_HORZ,
			y: -finalWidth * FRAME_TOP,
			containerWidth: width,
			containerHeight: width,
			width: finalWidth,
			height: finalWidth * HEIGHT_RATIO,
			borderRadius: null,
		}
	}
}

export const getCardFullDimensions = (width, borderless) => {
	if(borderless){
		const borderSize = width * BORDER_RATIO;
		return {
			x: -borderSize,
			y: -borderSize,
			containerWidth: width,
			containerHeight: (width * HEIGHT_RATIO - borderSize * 2) / (width - borderSize * 2) * width,
			width: width + borderSize * 2,
			height: (width + borderSize * 2) * HEIGHT_RATIO,
			borderRadius: (width - borderSize * 2) * BORDER_RADIUS_RATIO,
		}
	} else {
		return {
			x: 0,
			y: 0,
			width,
			height: width * HEIGHT_RATIO,
			containerWidth: width,
			containerHeight: width * HEIGHT_RATIO,
			borderRadius: width * BORDER_RADIUS_RATIO,
		}
	}
}

export const getCardImageDimensions = (width) => {
	const endWidthAmount = 1 - (CARD_IMAGE_HORZ_RATIO * 2);
	const finalWidth = width / endWidthAmount;
	const horzSpacing = finalWidth * CARD_IMAGE_HORZ_RATIO;
	return {
		x: -horzSpacing,
		y: -finalWidth * CARD_IMAGE_TOP_RATIO,
		containerWidth: width,
		containerHeight: finalWidth * HEIGHT_RATIO - (finalWidth * CARD_IMAGE_BOTTOM_RATIO + finalWidth * CARD_IMAGE_TOP_RATIO),
		width: finalWidth,
		height: (width + horzSpacing * 2) * HEIGHT_RATIO,
		borderRadius: 0,
	}
}

export const getCardSmallestImageSource = (card, width) => {
	const dpi = window.devicePixelRatio;
	let resolution = "full-jpg";
	let lowestResolution = null;
	const images = Object.keys(card?.images || {});

	for(let i = 0; i<images.length || 0; i++){
		const components = images[i].split("-");
		try {
			const res = parseInt(components[0]);
			if(typeof(res) === "number" && res > width * dpi && (!lowestResolution || res < lowestResolution)){
				resolution = images[i];
				lowestResolution = res;
			}
		} catch (error) {
			// All good
		}
	}
	return card?.images && card.images[resolution];
}


export const getCardSquareImageDimensions = (width) => {
	const endWidthAmount = 1 - (SQUARE_HORZ * 2);
	const finalWidth = width / endWidthAmount;
	return {
		x: -finalWidth * SQUARE_HORZ,
		y: -finalWidth * SQUARE_TOP,
		containerWidth: width,
		containerHeight: width,
		width: finalWidth,
		height: finalWidth * HEIGHT_RATIO,
		borderRadius: 0,
	}
}


