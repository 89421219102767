import firebase from './../data/firebase';
import { getAuth, signInWithEmailAndPassword } from "firebase/auth"
import useSharedState from "./useSharedState";
import { useEffect } from "react";

const auth = getAuth(firebase);

const useAuth = () => {
	const [ user, setUser ] = useSharedState("auth", null);
	
	useEffect(() => {
		return auth.onAuthStateChanged(user => setUser(user));
	}, [ setUser ]);

	return user;
}

export const signIn = (email, password) => signInWithEmailAndPassword(auth, email, password);

export const signOut = () => auth.signOut();

export default useAuth;