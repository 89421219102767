import React from 'react'
import InlineSVG from 'svg-inline-react'
import iconMap from "./../assets/iconMap.json";
import styled from "styled-components/macro";

const iconCache = {};

const iconSizeRegex = new RegExp('svg width=".*?" height=".*?"')

export const S = {
	Icon: styled.div`
		position: relative;
		width: 18px;
		height: 18px;
		flex-shrink: 0;

		${({size}) => size && `
			width: ${size}px;
			height: ${size}px;
		`}

		transition: filter 500ms;

		&.chevron {
			width: 14px;
			height: 14px;
		}
	`
}

const getIcon = config => {
	const { icon, alt="", width, height } = config;

	const cacheKey = `${icon}_${alt}_${width}_${height};`
	if(iconCache[cacheKey]){
		return iconCache[cacheKey];
	}

	if(!iconMap[icon]){
		console.error("Couldn't find icon ", icon);
		return null;
	}

	iconCache[cacheKey] = iconMap[icon]
			.replace(`<title>icons/${icon}</title>`, `<title>${alt}</title>`)
			.replace(iconSizeRegex, `svg width="${width}" height="${height}"`)

	return iconCache[cacheKey];
}

const inlineSvgStyle = {
	position: 'absolute',
	left: '50%',
	top: '50%',
	transform: 'translate(-50%, -50%)',
	flexShrink: 0,
}

class Icon extends React.PureComponent {

	state = {
		icon: null,
		pixelWidth: 1,
		pixelHeight: 1,
	}

	componentDidMount() {
		this.update()
	}

	componentWillUnmount() {
		if (this.unsub) {
			this.unsub()
		}
	}

	update(props = this.props) {
		this.updates = (this.updates || 0) + 1
		if (this.updates > 200) {
			console.log(
				`Icon ${props.icon} has updated a lot. Please make sure this isn't being hammered, this is an extensive op.`
			)
		}
		if (this.updates > 50000) {
			console.log(
				`Icon ${props.icon} updates way too high - aborting to avoid likely crash.`
			)
			return
		}
		let { icon, alt } = props
		let { pixelWidth, pixelHeight } = this.state
		// let promise = getIcon({ icon, color, alt, width: pixelWidth, height: pixelHeight });
		this.setState({
			icon: getIcon({ icon, alt, width: pixelWidth, height: pixelHeight })
		});
		// this.currentPromise = promise
		// let shouldUpdate = true
		// this.unsub = () => (shouldUpdate = false)
		// promise.then(icon => {
		// 	this.unsub = null
		// 	if (this.currentPromise === promise && shouldUpdate) {
		// 		this.setState({
		// 			icon
		// 		})
		// 	}
		// })
	}

	componentDidUpdate() {
		if (
			this.lastIcon !== this.props.icon ||
			this.lastAlt !== this.props.alt
		) {
			this.lastIcon = this.props.icon
			this.lastAlt = this.props.alt
			this.update()
		}
		let { pixelWidth, pixelHeight } = this.state
		if (this.containerRef) {
			let { clientWidth, clientHeight } = this.containerRef
			if (clientWidth !== pixelWidth || clientHeight !== pixelHeight) {
				this.setState(
					{
						pixelWidth: clientWidth,
						pixelHeight: clientHeight,
					},
					() => this.update()
				)
			}
		}
	}

	render() {
		let { style, alt, className, debug, size, onClick, onMouseEnter, onMouseLeave } = this.props
		let { icon } = this.state
		if (!icon) {
			return null
		}
		if(debug){
			console.log("Debug icon ", icon);
		}
		return (
			<S.Icon
				onClick={onClick}
				onMouseEnter={onMouseEnter}
				onMouseLeave={onMouseLeave}
				className={className}
				ref={ref => (this.containerRef = ref)}
				style={style}
				size={size}
			>
				<InlineSVG
					raw
					style={inlineSvgStyle}
					src={icon}
					alt={alt}
				/>
			</S.Icon>
		)
	}
}

Icon.defaultProps = {
	alt: '',
}

export default Icon
