import { createGlobalStyle } from "styled-components/macro";
import { normalize } from "styled-normalize";

export const colors = {
	"--color-grey-base": "#2B2B2B",
	"--color-grey-dark": "#212121",
	"--color-grey-mid": "#353535",
	"--color-grey-high": "#D2D2D2",

	"--color-interest": "#62798B",
	"--color-interest-selected": "#5E8AAD",
	"--color-interest-cta": "#7CBFFF",

	"--color-grey-light": "#5F5F5F",

	"--color-selection": "#6B889E",
	"--color-selection-dark": "#4A7596",

	"--color-cta": "#1381D9",
	"--color-cta-50": "rgba(60, 169, 255, 0.5)",

	"--color-inactive": "#A0A0A0",

	"--color-line-dark": "#1B1B1B",

	"--color-text": "#D2D2D2",
	"--color-text-light": "#eeeeee",

	"--pitch-red": "#CF2A2A",
	"--pitch-yellow": "#ECD60D",
	"--pitch-blue": "#369EE6",

	"--color-and": "#A03C14",
	"--color-or": "#6F9908",

	"--color-warn-light": "#985F52",

	"--color-error": "#FF4949",
	"--color-error-50": "rgba(255, 73, 73, 0.5)",
	"--color-error-background": "#A62E2E",

	"--gradient-gold": "linear-gradient(152deg, rgba(236,224,182,1) 0%, rgba(179,155,82,1) 100%)",

	"--box-shadow-tiny": "1px 1px 2px rgba(0, 0, 0, 0.5)",
	"--box-shadow-medium": "6px 6px 12px rgba(0, 0, 0, 0.5)",
}

export const pitchColors = [ "--pitch-red", "--pitch-yellow", "--pitch-blue" ]

const AppStyles = createGlobalStyle`

	${normalize}

	hr {
		width: 100%;
		height: 1px;
		border: none;
		background-color: var(--color-grey-dark);
		margin: 6px 0px;
	}

	html {
		${ Object.keys(colors).map(col => `${col}: ${colors[col]};`) }

		--font-size-tiny: 10px;
		--font-size-small: 12px;
		--font-size-medium: 14px;
		--font-size-large: 18px;

	  	--font-weight-light: 300;
		--font-weight-regular: 400;
		--font-weight-semibold: 600;
		--font-weight-bold: 700;
	}

`;


export default AppStyles;