import Button from "./Button";
import styled from "styled-components";

const S = {
	Input: styled.input`
		opacity: 0;
		cursor: pointer;
	`
}

const FileInput = props => {
	const { clearIndex, onChange } = props;

	return <Button
		{...props}
	>
		<S.Input
			key={clearIndex}
			type="file"
			className="absolute-expand"
			onChange={evt => onChange(evt.target.files ? evt.target.files[0] : null)}
		/>
	</Button>
}

export default FileInput;