
export const emptyCondition = { type: "group", operator: "and", value: [] };

export const conditionValid = condition => {
	const c = conditions[condition?.type];
	return c
		&& dataTypeOperators[c?.dataType]?.includes(condition?.operator)
		&& condition?.value !== null && condition?.value !== undefined
	;
}

export const dataTypeOperators = {
	numeric: [ "=", ">", "<", ">=", "<=", "!=" ],
	array: [ "includes" ],//, "excludes" ],
	string: [ "=" ],
	card: [ "=" ],
}

export const conditions = {
	power: {
		label: "Power",
		dataType: "numeric",
		defaultValue: 4,
		minValue: 0,
		maxValue: 14,
	},
	defense: {
		label: "Defense",
		dataType: "numeric",
		defaultValue: 3,
		minValue: 0,
		maxValue: 6,
	},
	pitch: {
		label: "Pitch",
		dataType: "numeric",
		defaultValue: 3,
		minValue: 0,
		maxValue: 3,
	},
	cost: {
		label: "Cost",
		dataType: "numeric",
		defaultValue: 0,
		minValue: 0,
		maxValue: 10,
	},
	types: {
		label: "Type",
		dataType: "array",
		defaultValue: "action",
	},
	classes: {
		label: "Class",
		dataType: "array",
		defaultValue: "ranger",
	},
	talents: {
		label: "Talents",
		dataType: "array",
		defaultValue: "ice",
	},
	subtypes: {
		label: "Subtypes",
		dataType: "array",
		defaultValue: "arrow",
	},
	id: {
		label: "Card",
		dataType: "card",
		defaultValue: "weave-ice-red",
	},
}

export const operators = {
	">": {
		label: ">",
	},
	"<": {
		label: "<",
	},
	">=": {
		label: ">=",
	},
	"<=": {
		label: "<=",
	},
	"=": {
		label: "=",
	},
	"!=": {
		label: "!=",
	},
	"includes": {
		label: "incl.",
	},
	"excludes": {
		label: "excl.",
	},
}
