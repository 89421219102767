export const arrayGreaterThan = (arr1, arr2) => {
    for (let i=0; i<arr1.length; i++) {
        if (arr2.length <= i) return true; // if arr2 is a strict prefix of arr1 then arr1 > arr2
        if (arr1[i] > arr2[i]) return true;
        if (arr1[i] < arr2[i]) return false;
    }
    return false
}

export const months = [ "January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December" ];
const monthsShort = [ "Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec" ];

export const month = (index, short) => short ? monthsShort[index] : months[index];

export const removeFullStop = string => string?.length && string.charAt(string.length - 1) === "." ? string.substring(0, string.length - 1) : string;

export const nounList = (nouns, negative) => nouns.length === 0 ? ""
	: nouns.length === 1 ? nouns[0]
	: `${nouns.slice(0, nouns.length-1).join(', ')}${nouns.length > 2 ? "," : ""} ${ negative ? "or" : "and"} "${nouns[nouns.length - 1]}"`

export const currency = number => number % 1 === 0 ? number : number.toFixed(2);

export const localStorageAvailable = () => {
	try {
		const testItem = '__storage_test__';
		window.localStorage.setItem(testItem, true);
		window.localStorage.removeItem(testItem);
		return true;
	}
		catch(e) {
		return false;
	}
}

export const getInitials = (string, padSecondLetterIfSingle=true) => {
	let initials = "";
	string.split(" ").forEach(n => {
		if(n.length > 0){
			initials += n.charAt(0);
		}
	})
	if(initials.length > 2){
		initials = initials.substring(0, 2);
	}
	if(initials.length === 1 && string.length > 1 && padSecondLetterIfSingle){
		initials += string[1];
	}
	return initials;
}

export const selectText = element => {
	window.setTimeout(() => {
		let sel, range;
		if (window.getSelection && document.createRange) {
			range = document.createRange();
			range.selectNodeContents(element);
			sel = window.getSelection();
			sel.removeAllRanges();
			sel.addRange(range);
		} else if (document.body.createTextRange) {
			range = document.body.createTextRange();
			range.moveToElementText(element);
			range.select();
		}
	}, 1);
}

export const currentLinkRoot = () => `${window.location.protocol}//${window.location.hostname}${window.location.hostname === 'localhost' ? `:${window.location.port}` : ""}`;

export const uniqueArray = (arr) => {
	const map = {};
	arr.forEach(a => map[a] = a);
	return Object.values(map);
}

export const arrayInstanceCounts = (arr) => {
	const map = {};
	arr.forEach(a => map[a] = map[a] ? map[a] + 1 : 1 );
	return map;
}

export const validEmail = email => {
	return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)
}

export const padString = (string, width, char="0") => {
  return string.length >= width ? string : new Array(width - string.length + 1).join(char) + string;
}

export const wrapMod = (num, mod) => {
	while(num < 0){
		num += mod;
	}
	return num % mod;
}

export const capitalise = str => str.charAt(0).toUpperCase() + str.slice(1);

// From https://stackoverflow.com/questions/2901102/how-to-print-a-number-with-commas-as-thousands-separators-in-javascript
export const thousandsSeparator = number => (number || 0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
export const thou = thousandsSeparator;

export const pl = count => (count === 1 || count?.length === 1) ? "" : "s";
export const invPl = count => (count === 1 || count?.length === 1) ? "s" : "";
export const plApostrophe = count => (count === 1 || count?.length === 1) ? "'s" : "s'";
export const plY = count => (count === 1 || count?.length === 1) ? "y" : "ies";
export const these = (count, capitalise) => (count === 1 || count?.length === 1) ? (capitalise ? "This" : "this") : (capitalise ? "These" : "these");
export const were = count => (count === 1 || count?.length === 1) ? "was" : "were";
export const they = count => (count === 1 || count?.length === 1) ? "it" : "they";
export const have = count => (count === 1 || count?.length === 1) ? "has" : "have";
export const are = count => (count === 1 || count?.length === 1) ? "is" : "are";
export const havent = count => (count === 1 || count?.length === 1) ? "hasn't" : "haven't";
// TODO: This is complicated, just extend it as needed
export const an = word => {
	if(!word || word.length === 0){
		return "a";
	}
	let lc = word.toLowerCase();
	switch(lc[0]){
		case "e": return "an";
		case "a": return "an";
		default: return "a";
	}
}

export const truncate = (string, wordCount) => {
	const words = string.split(" ")

	if (words.length > wordCount) {
		return `${words.splice(0, wordCount).join(" ")}...`
	} else {
		return string
	}
}

// From https://stackoverflow.com/questions/400212/how-do-i-copy-to-the-clipboard-in-javascript?page=1&tab=votes#tab-top
const fallbackCopyTextToClipboard = text => {
	const textArea = document.createElement("textarea");
	textArea.value = text;

	// Avoid scrolling to bottom
	textArea.style.top = "0";
	textArea.style.left = "0";
	textArea.style.position = "fixed";

	document.body.appendChild(textArea);
	textArea.focus();
	textArea.select();

	try {
		document.execCommand('copy');
		//const msg = successful ? 'successful' : 'unsuccessful';
		// console.log('Fallback: Copying text command was ' + msg);
	} catch (err) {
		console.error('Unable to copy', err);
	}

	document.body.removeChild(textArea);
}
export const copyToClipboard = text => {
	if (!navigator.clipboard) {
		fallbackCopyTextToClipboard(text);
		return;
	}
	navigator.clipboard.writeText(text).then(function() {
		// console.log('Async: Copying to clipboard was successful!');
	}, function(err) {
		console.error('Async: Could not copy text: ', err);
	});
}

export const getDuplicateName = name => {

	let newName;
	const matches = name.match(/(.*)?(?:\s\((\d+)\))/);
	if(matches?.length > 1){
		const number = parseInt(matches[2]);
		if(!isNaN(number)){
			newName = `${matches[1]} (${number + 1})`;
		}
	}
	if(!newName){
		newName = `${name} (2)`;
	}
	return newName;
}

export const emptyArray = size => Array.apply(null, Array(size));

export const emptyArrayWithValue = (size, value) => Array.apply(null, Array(size)).map(v => value);

export const cloneClassInstance = classInstance => Object.assign(Object.create(Object.getPrototypeOf(classInstance)), classInstance)


const thousands = [ "k", "M", "B", "T" ];

export const condensedInteger = integer => {

	let th = -1;
	let n = integer;
	while(n >= 1000){
		th++;
		n *= 0.001;
	}
	
	if(th >= 0){
		let nString = n;
		if(n % 1 !== 0){
			if(n >= 100){
				nString = Math.round(n);
			} else {
				nString = n.toFixed(1);
			}
		}
		return `${nString}${thousands[th]}`;
	} else {
		return n;
	}

}


