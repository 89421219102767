import Input from "./Input";
import { useEffect, useState, useCallback } from "react";

const NumberInput = props => {
	const { value, onChange, numberType="integer", onFocus, onBlur, min, max } = props;

	const [ editingValue, setEditingValue ] = useState(value);
	const [ focused, setFocused ] = useState(false);

	useEffect(() => {
		// TODO: Validate this
		setEditingValue(value);
	}, [ value ]);

	const handleValue = useCallback(v => {
		if(v.length === 0){
			onChange(0);
			setEditingValue("");
		} else {
			const number = numberType === "integer" ? parseInt(v) : parseFloat(v);
			if(!isNaN(number)){
				let validatedNumber = number;
				if(min !== null && min !== undefined){
					validatedNumber = Math.max(min, validatedNumber);
				}
				if(max !== null && max !== undefined){
					validatedNumber = Math.min(max, validatedNumber);
				}
				onChange(validatedNumber);
				setEditingValue(number);
			}
		}
	}, [ onChange, numberType, min, max ]);

	const handleFocus = useCallback(evt => {
		setFocused(true);
		setEditingValue(value)
		if(onFocus){
			onFocus(evt);
		}
	}, [ onFocus, value ])

	const handleBlur = useCallback(evt => {
		setFocused(false);
		if(onBlur){
			onBlur(evt);
		}

		if(!editingValue?.length){
			setEditingValue(0);	
		} else {
			const number = numberType === "integer" ? parseInt(editingValue) : parseFloat(editingValue);
			if(isNaN(number)){
				setEditingValue(`${value}`);
				onChange(value);
			} else {
				if(max !== null && max !== undefined && number > max){
					setEditingValue(max);
					onChange(max);
				}
				if(min !== null && min !== undefined && number < min){
					setEditingValue(min);
					onChange(min);
				}
			}
		}
	}, [ onBlur, numberType, editingValue, onChange, min, max, value ])

	return <Input
		{...props}
		value={focused ? editingValue : value}
		onChange={handleValue}
		onFocus={handleFocus}
		onBlur={handleBlur}
	/>

}

export default NumberInput;