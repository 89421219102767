import { useRef, useCallback, useEffect } from "react";

const groups = {};
let iid = 0;

const useOutsideClickEffect = (boundsRef, effect, blockingGroup) => {

	const idRef = useRef(-1);

	const handleClick = useCallback(evt => {
		if(!boundsRef.current){
			return;
		}
		if(
			blockingGroup &&
			groups[blockingGroup]?.length &&
			groups[blockingGroup][groups[blockingGroup].length - 1] !== idRef.current
		){
			return;
		}
		const { x, y, width, height } = boundsRef.current.getBoundingClientRect();
		let { clientX, clientY } = evt;
		if(clientX === undefined || clientY === undefined){
			return;
		}
		if (clientX > x && clientX < x + width && clientY > y && clientY < y + height) {
			return
		}
		if (effect) {
			effect(evt)
		}
	}, [boundsRef, effect, blockingGroup]);


	useEffect(() => {
		if(!effect || !boundsRef){
			return null;
		}
		if(idRef.current === -1){
			idRef.current = iid++;
		}
		
		if(blockingGroup){
			if(!groups[blockingGroup]){
				groups[blockingGroup] = [];
			}
			groups[blockingGroup].push(idRef.current);
		}
		document.addEventListener('mousedown', handleClick)
		
		return () => {
			if(blockingGroup && groups[blockingGroup]){
				groups[blockingGroup] = groups[blockingGroup].filter(id => id !== idRef.current);
			}
			document.removeEventListener('mousedown', handleClick)
		}
	}, [effect, boundsRef, handleClick, blockingGroup])


}

export default useOutsideClickEffect;