import styled from "styled-components/macro";
import Auth from "./../layouts/Auth";
import { useLinkClickHandler } from "react-router-dom";
import logo from "./../assets/images/header-logo.png";

const S = {
	Container: styled.div`
		display: flex;
		align-items: center;
		height: 60px;
		background-color: #2B2B2B;

		z-index: 1;

		box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
	`,
	Logo: styled.img`
		width: auto;
		height: 100%;
		cursor: pointer;
		transition: opacity 300ms;
		&:hover {
			opacity: 0.95;
		}
	`,
	HeaderLink: styled.h4`

		&:hover {
			text-decoration: underline;
			cursor: pointer;
		}
	`
}

const Header = () => {

	const handleHomeClick = useLinkClickHandler("/");

	return <S.Container>
		<S.Logo onClick={handleHomeClick} src={logo} />
		<div className="space-12" />
		<S.HeaderLink>My Decks</S.HeaderLink>
		<div className="space-24" />
		<div className="space-12" />
		<S.HeaderLink>Browse</S.HeaderLink>
		<div className="space-24" />
		<div className="space-12" />
		<S.HeaderLink>About</S.HeaderLink>
		<div className="space-24" />
		<div className="space-12" />
		<div className="flex" />
		<Auth />
		<div className="space-12" />
	</S.Container>

}


export default Header;