import styled from "styled-components/macro";
import Card from "./Card";

const S = {
	CardImage: styled(Card)`
		overflow: hidden;
		border-radius: 2px;
	`
}

const CardImage = props => <S.CardImage dimensions="square" {...props} />

export default CardImage;