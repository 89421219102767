import { useMemo, useEffect, Fragment, useState } from "react";
import {
	CardHint,
	Icon,
	Input,
	StatefulInput,
	Button,
	NumberInput,
	DropdownList,
	StandardDropdownList,
	StatCreator,
	Popup,
	Condition
} from "./../components";
import {
	addBoard,
	setCardTotal, newBoard, renameBoard, updateTrackedBoardId, removeBoard,
	addStat, removeStat, editStat
} from './../hooks/useDatabase';
import styled, { css } from "styled-components";
import {
	getBoardsUniqueCards,
	getBoardsMaxCardCounts,
	sortCards,
	getNormalCards,
	getWeapons,
	getEquipment,
	getCardsCount,
	getBoardsCardsCount,
	tryGetInheritedCardsMap
} from "./../utils/DeckUtils";
import { pitchColors } from "./../AppStyles";
import classnames from "classnames";
import { getCardsStatCount } from "./../utils/StatUtils";
import { Scrollbars } from "react-custom-scrollbars";
import formats from "./../data/formats";
import { clampCardLimit, cardTotalValid } from "./../utils/DeckUtils";

const S = {
	Table: styled.div`
		display: flex;
	`,
	Col: styled.div`
		width: ${({width}) => `${(width || 150)}px;`}
	`,
	Cell: styled.div`
		display: flex;
		align-items: center;
		width: 100%;
		position: relative;
		height: 24px;

		transition: background-color 150ms;
		border-radius: 2px;

		border: 1px solid transparent;

		${({centered}) => centered && css`
			justify-content: center;
		`}
		${({right}) => right && css`
			justify-content: flex-end;
		`}

		${({highlighted}) => highlighted && css`
			background-color: var(--color-grey-mid);
		`}

		${({ghosted}) => ghosted && css`
			opacity: 0.5;
		`}

		${({hasError}) => hasError && css`
			border-color: var(--color-error-background);
		`}
	`,
	ErrorIconContainer: styled.div`
		position: absolute;
		z-index: 1;
		top: -6px;
		right: -6px;
		background-color: var(--color-error-background);
		border-radius: 12px;
		transform: tralsnate(-50%, -50%);
	`,
	ErrorPopup: styled(Popup)`
		width: 200px;
		padding: 12px;
	`,
	CardName: styled.p`
		margin: 0px;
		white-space: nowrap;
		color: white;
	`,
	HighlighCircle: styled.div`

		width: 24px;
		height: 24px;
		border-radius: 12px;
		cursor: text;

		&:hover {
			background-color: var(--color-grey-base);
		}

		${({active}) => active && `
			background-color: #684A4A;
		`}
	`,
	CardCount: styled(NumberInput)`
		margin: 0px;
		width: 24px;

		${({isTracked}) => isTracked && css`
			opacity: 0.5;
		`}
	`,
	CardColor: styled.div`
		width: 12px;
		height: 12px;
		border-radius: 6px;
		margin: 0px 12px 0px 0px;
		flex-shrink: 0;

		${({color}) => `background-color: var(${color});`}
	`,
	SectionTitle: styled.h6`
		margin: 0px;
		${({centered}) => centered && `text-align: center;`}
	`,
	StatTitle: styled.p`
		margin: 0px;
		width: 100%;
		border-radius: 12px;
		text-align: right;
		cursor: pointer;
		padding: 2px 12px;
		${({color}) => color && `background-color: ${color};`}
	`,
	CellNumber: styled.p`
		margin: 0px;
		text-align: center;
		width: 100%;
	`,
	CountButton: styled.div`
		cursor: pointer;
		fill: var(--color-text);
		position: relative;

		width: 24px;
		height: 24px;
		border-radius: 12px;

		${({isDisabled}) => isDisabled && css`
			opacity: 0.3;
			pointer-events: none;
		`}

		&:hover {
			background-color: var(--color-grey-light);
		}

		${({isHidden}) => isHidden && `opacity: 0;`}
	`,
	Clickable: styled.div`
		margin: 1px;
		border-radius: 2px;
		cursor: pointer;

		&:hover {
			background-color: var(--color-grey-light);
		}
	`,
	CellButton: styled.div`
		margin: 1px;
		border-radius: 2px;
		padding: 3px 6px;
		cursor: pointer;

		border: 1px solid var(--color-grey-light);

		&:hover {
			background-color: var(--color-grey-light);
		}

		font-weight: var(--font-weight-semibold);
		color: var(--color-grey-high);
		font-size: var(--font-size-medium);
	`,
	CellButtonIcon: styled(Icon)`
		width: 14px;
		height: 14px;
		margin-right: 3px;
	`,
	StatCreatorPopup: styled(Popup)`
		width: 280px;
		padding: 12px;
	`,
	BoardTrackingOption: styled.div`
		display: flex;
		align-items: center;
		padding: 0px 12px;
		background-color: transparent;
		white-space: nowrap;
		cursor: pointer;

		${({selected}) => selected && `background-color: var(--color-selection-dark);`}

		&:hover {
			background-color: var(--color-selection);
		}
	`,
	StatPopup: styled(Popup)`
		width: 300px;
		padding: 18px;
	`
}

const Cell = props => {
	const {
		hoveredRow, setHoveredRow, hoveredCell, setHoveredCell, row, cell, highlightOnCell,
		children, centered, right, ghosted, onMouseDown, onMouseEnter, onMouseLeave, hasError
	} = props;
	return <S.Cell
		centered={centered}
		right={right}
		hasError={hasError}
		ghosted={ghosted}
		onMouseDown={onMouseDown}
		highlighted={
			(row !== undefined && row === hoveredRow)
			|| (highlightOnCell && cell !== undefined && cell === hoveredCell) }
		onMouseEnter={evt => {
			if(onMouseEnter){
				onMouseEnter(evt);
			}
			if(setHoveredCell && cell !== undefined){
				setHoveredCell(cell)	
			}
			if(setHoveredRow && row !== undefined){
				setHoveredRow(row)	
			}
		}}
		onMouseLeave={evt => {
			if(onMouseLeave){
				onMouseLeave(evt);
			}
			if(setHoveredCell){
				setHoveredCell(null)	
			}
			if(setHoveredRow){
				setHoveredRow(null);
			}
		}}
	>
		{ children }
		{
			hasError ? <S.ErrorIconContainer>
				<Icon icon="error" />
			</S.ErrorIconContainer> : null
		}
	</S.Cell>
}

const SectionTitleCell = props => {
	return <Cell {...props}>
		<S.SectionTitle>{props.name}</S.SectionTitle>
		{ props.children }
	</Cell>
}

const StatTitleCell = props => {
	const { name, color, deck, stat } = props;
	const [ open, setOpen ] = useState(false);
	return <Cell
			{ ...props }
			onMouseDown={open ? null : () => setOpen(true)}
		>
		<S.StatTitle color={color}>{name}</S.StatTitle>
		<S.StatPopup
			open={open}
			onClose={() => setOpen(false)}
			pointerEvents
		>
			<StatefulInput
				className="h3 simple align-left"
				value={name}
				blurBehaviour="submit"
				onSubmit={value => editStat(deck, stat, { name: value })}
			/>
			<div className="space-6" />
			{
				stat?.condition ? <Condition
					condition={stat.condition}
					onChange={condition => editStat(deck, stat, { condition })}
				/> : null
			}
			<div className="space-6" />
			<div className="flex-container-centered">
				<Button
					className="small cta"
					label="Search"
					icon="search"
					onClick={() => console.log("Search!")}
				/>
				<div className="space-12" />
				<Button
					className="small"
					label="Remove"
					icon="close"
					onClick={() => removeStat(deck, stat)}
				/>
			</div>
		</S.StatPopup>
	</Cell>
}

const EmptyCell = props => {
	return <Cell {...props} />
}

const CountButton = ({icon, isHidden, onClick, disabled}) => {
	return <S.CountButton
		isHidden={isHidden}
		onMouseDown={evt => {
			evt.preventDefault();
			onClick(evt)
		}}
		isDisabled={disabled}
	>
		<Icon className="absolute-center" icon={icon} />
	</S.CountButton>
}

const CountCell = props => {
	const { card, count, isTracked, onAmount, max, format } = props;
	const [ hovered, setHovered ] = useState(false);
	const [ stagingCount, setStagingCount ] = useState(count);

	const lowerThanMax = count < max;
	const [ focused, setFocused ] = useState(false);
	const [ focusIndex, setFocusIndex ] = useState(0);

	useEffect(() => {
		setStagingCount(count);
	}, [ count ]);

	return <Cell
		centered
		{...props}
		onMouseEnter={() => setHovered(true)}
		onMouseLeave={() => setHovered(false)}
	>
		<CountButton
			isHidden={!hovered}
			icon="remove"
			onClick={() => onAmount(count - 1)}
			disabled={!cardTotalValid(format, card, count - 1)}
		/>
		<S.HighlighCircle
			active={lowerThanMax}
			onClick={() => setFocusIndex(f => f + 1)}
		>
			<S.CardCount
				isTracked={isTracked}
				onFocus={() => setFocused(true)}
				onBlur={() => setFocused(false)}
				focus={focusIndex}
				select={focusIndex}
				className={classnames("simple", { "pointer-events-none": !focused })}
				value={stagingCount}
				onChange={setStagingCount}
				blurBehaviour="submit"
				type="integer"
				onSubmit={() => {
					if(cardTotalValid(format, card, stagingCount)){
						onAmount(stagingCount)
					} else {
						const limit = clampCardLimit(format, card, stagingCount);
						onAmount(limit);
						setStagingCount(limit);
					}
				}}
			/>
		</S.HighlighCircle>
		<CountButton
			isHidden={!hovered}
			icon="add"
			onClick={() => onAmount(count + 1)}
			disabled={!cardTotalValid(format, card, count + 1)}
		/>
	</Cell>
}

const CardCell = props => {
	const { card } = props;
	return <Cell {...props}>
		<S.CardColor color={pitchColors[card.pitch - 1]}  />
		<div className="space-6 no-shrink" />
		<CardHint card={card}>
			<S.CardName>{card?.name}</S.CardName>
		</CardHint>
	</Cell>
}

const sides = [ "right", "left" ];
const ChangeTrackingPopup = ({deck, board, boards}) => {
	const [ open, setOpen ] = useState(false);

	const otherBoards = useMemo(() => boards.filter(b => b.id !== board.id), [ board, boards ]);

	return <div
		className="absolute-expand"
		onMouseEnter={() => setOpen(true)}
		onMouseLeave={() => setOpen(false)}
	>
		<BoardTracking
			open={open}
			selectedBoardId={board?.trackedBoardId}
			boards={otherBoards}
			sides={sides}
			linger={100}
			onClick={trackedBoardId => updateTrackedBoardId(deck, board, trackedBoardId)}
		/>
	</div>
}

const ErrorTitle = ({title}) => <div className="flex-container-centered">
	<h4 className="margin-6-0">{title}</h4>
	<div className="flex" />
	<Icon icon="error" />
</div>

const renderError = (error) => {
	switch(error?.message){
		case "board-missing":
			return <>
				<ErrorTitle title="Board Missing" />
				<p><span className="bold">{error.boardTrace[error.boardTrace.length - 1].name}</span> tracks a board that no longer exists.</p>
			</>
		case "cyclic-tracking":
			const toDisplay = error.boardTrace.slice();
			const loopStart = error.boardTrace[error.boardTrace.length - 1];
			toDisplay.push(error.boardTrace.find(b => b.id === loopStart.trackedBoardId));
			return <>
				<ErrorTitle title="Cyclic Tracking" />
				<p>Some boards track each other in an infinite loop:</p>
				{
					toDisplay.map((b, i) => <div key={i} className="flex-container-centered">
						{
							i === 0 ? null : <>
								<Icon className="margin-0-6" icon="track-trace" />
								<p className="margin-0"><i>tracks</i></p>
								<div className="space-6" />
							</>
						}
						<p className="margin-0 bold">{b.name}</p>
					</div>)
				}
				<p></p>
			</>
		default:
			console.error(`Unhandled error type ${error?.message}`);
			return null;
	}
}

const BoardTitleCell = (props) => {
	const { deck, boards, board, value, onRename, onRemove, placeholder, focus, error } = props;
	const [ stagingValue, setStagingValue ] = useState(value);
	const [ open, setOpen ] = useState(false);
	const [ hovered, setHovered ] = useState(false);
	const [ focused, setFocused ] = useState(false);
	const [ focusIndex, setFocusIndex ] = useState(focus ? 1 : 0);

	useEffect(() => {
		setStagingValue(value);
	}, [ value ])

	useEffect(() => {
		if(focus){
			setFocusIndex(1);
		}
	}, [ focus ]);

	const options = useMemo(() => ({
		rename: {
			label: "Rename",
			icon: "edit",
			onClick: () => {
				setFocusIndex(fi => fi + 1);
			}
		},
		delete: {
			label: "Delete",
			icon: "delete",
			onClick: onRemove
		},
		reTrack: {
			label: <>
				<p className="margin-0 no-wrap flex">{
					board.trackedBoardId ? <span className={classnames("bold", { "error-red": !!error })}>{boards?.find(b => b.id === board.trackedBoardId)?.name || "(?)"}</span>
						: <i>Track board...</i>}</p>
				<Icon icon="nested-dropdown" />
			</>,
			icon: "track",
			children: <ChangeTrackingPopup
				deck={deck}
				boards={boards}
				board={board}
			/>
		}
	}), [ onRemove, deck, board, boards, error ])

	return <div className="relative">
		<Cell
			{...props}
			hasError={!!error}
			onMouseEnter={() => setHovered(true)}
			onMouseLeave={() => setHovered(false)}
		>
			<S.Clickable onMouseDown={open ? null : () => setOpen(true)}>
				<Input
					className={classnames("simple", {"pointer-events-none": !focused})}
					focus={focusIndex}
					select={focusIndex}
					onFocus={() => setFocused(true)}
					onBlur={() => setFocused(false)}
					placeholder={placeholder}
					value={stagingValue}
					onChange={setStagingValue}
					blurBehaviour="submit"
					onSubmit={() => onRename(stagingValue)}
				/>
			</S.Clickable>
		</Cell>
		<StandardDropdownList
			open={open}
			onClose={() => setOpen(false)}
			closeOnClick
			options={options}
		/>
		<S.ErrorPopup
			open={!!error && hovered}
		>
			{
				error ? renderError(error) : null
			}
		</S.ErrorPopup>
	</div>
}

const BoardTrackingOption = props => {
	const { label, icon } = props;
	return <S.BoardTrackingOption {...props}>
		<Icon icon={icon} />
		<div className="space-6" />
		{
			typeof(label) === "string" ? <p className="margin-6-0">{label}</p> : label
		}
	</S.BoardTrackingOption>
}

const BoardTracking = props => {
	const { onClick, boards, selectedBoardId } = props;
	return <DropdownList
		{...props}
	>
		{
			boards.map((b, i) => <BoardTrackingOption
				key={i}
				selected={b.id === selectedBoardId}
				label={<p className="margin-6-0 nowrap">Track <span className="bold">{b.name}</span></p>}
				icon="track"
				onClick={() => onClick(b.id)}
			/>)
		}
		<BoardTrackingOption
			label="Independent"
			icon="no-tracking"
			onClick={() => onClick(null)}
			selected={!selectedBoardId}
		/>
	</DropdownList>
}

const NewBoardCell = props => {
	const { onNew, boards } = props;
	const [ open, setOpen ] = useState(false);

	const handleNewBoard = mergedDetails => {
		onNew(Object.assign({}, newBoard, { name: "New board" }, mergedDetails));
		setOpen(false);
	}

	return <Cell {...props} centered className="relative">
		<S.CellButton className="flex-container" onMouseDown={open ? null : () => setOpen(true)}>
			<S.CellButtonIcon icon="add" />
			<span className="no-wrap">Board</span>
		</S.CellButton>
		<BoardTracking
			boards={boards}
			open={open}
			onClose={() => setOpen(false)}
			onClick={trackedBoardId => handleNewBoard({ trackedBoardId })}
		/>
	</Cell>
}

const DeckTable = ({deck, boards, stats, className}) => {

	const format = formats[deck?.format];

	const cards = getBoardsUniqueCards(boards, true);
	const weapons = sortCards(getWeapons(cards));
	const equipment = sortCards(getEquipment(cards));

	const maxCounts = getBoardsMaxCardCounts(boards);

	const normal = sortCards(getNormalCards(cards));

	const [ hoveredCell, setHoveredCell ] = useState(null);
	const [ hoveredRow, setHoveredRow ] = useState(null);

	const [ focusBoardId, setFocusBoardId ] = useState(null);

	const ghostedCards = {};
	Object.keys(maxCounts).forEach(c => maxCounts[c] === 0 ? ghostedCards[c] = true : null);

	const firstCellProps = { hoveredCell, hoveredRow, setHoveredRow };
	const firstRowProps = { hoveredCell, hoveredRow, setHoveredCell };
	const cellProps = { hoveredCell, hoveredRow, setHoveredCell, setHoveredRow };

	const index = [];
	index.push({ type: "title", component: <SectionTitleCell name="Weapons" /> })
	weapons.forEach(card => index.push({
		type: "card",
		card,
		component: <CardCell
			{...firstCellProps}
			cell={0}
			row={index.length + 1}
			key={card.id}
			card={card}
			ghosted={ghostedCards[card.id]}
		/>
	}))
	index.push({ type: "title", component: <SectionTitleCell name="Equipment" /> })
	equipment.forEach(card => index.push({
		type: "card",
		card,
		component: <CardCell
			{...firstCellProps}
			cell={0}
			row={index.length + 1}
			key={card.id}
			card={card}
			ghosted={ghostedCards[card.id]}
		/>
	}))
	index.push({ type: "title", component: <SectionTitleCell name="Cards" /> })
	normal.forEach(card => index.push({
		type: "card",
		card,
		component: <CardCell
			{...firstCellProps}
			cell={0}
			row={index.length + 1}
			key={card.id}
			card={card}
			ghosted={ghostedCards[card.id]}
		/>
	}));

	const [ inheritedCardsMaps, boardTrackingErrors ] = useMemo(() => {
		const ret = {};
		const errors = {};
		boards.forEach(b => {
			try {
				ret[b.id] = tryGetInheritedCardsMap(b, boards);
			} catch (error) {
				errors[b.id] = error;
			}
		})
		return [ ret, errors ];
	}, [ boards ]);

	const renderBoardCountCell = (board, card, cell, row) => {
		return <CountCell
			format={format}
			card={card}
			count={inheritedCardsMaps[board.id] && inheritedCardsMaps[board.id][card.id]?.count || 0}
			isTracked={!board?.cards?.find(c => c.id === card.id)}
			onAmount={amount => {
				const trackedCount = inheritedCardsMaps[board.trackedBoardId] && inheritedCardsMaps[board.trackedBoardId][card.id]?.count || 0;
				if(board.trackedBoardId && trackedCount === amount){
					setCardTotal(deck, board, card.id, 0, { allowGhosting: false });
				} else {
					setCardTotal(deck, board, card.id, amount, { allowGhosting: board.trackedBoardId })}
				}
			}
			max={maxCounts[card.id]}
			row={row}
			cell={cell}
			ghosted={ghostedCards[card.id]}
			{...cellProps}
		/>
	}

	const boardTitles = [];
	const boardIndices = [];
	boards?.forEach((b, i) => {
		boardTitles.push({
			type: "title",
			component: <BoardTitleCell
				value={b.name}
				onRename={name => renameBoard(deck, b, name)}
				onRemove={() => removeBoard(deck, b)}
				deck={deck}
				board={b}
				boards={boards}
				error={boardTrackingErrors[b.id]}
				placeholder={"Board"}
				focus={b.id === focusBoardId}
				{...firstRowProps}
				row={0}
				cell={i + 1}
				highlightOnCell
			/> 
		})
		let index = [];
		index.push({ type: "empty", component: <EmptyCell /> });
		weapons.forEach(card => index.push({type: "count", component: renderBoardCountCell(b, card, i + 1, index.length + 1)}));
		index.push({ type: "empty", component: <EmptyCell /> });
		equipment.forEach(card => index.push({type: "count", component: renderBoardCountCell(b, card, i + 1, index.length + 1)}));
		index.push({ type: "empty", component: <EmptyCell /> });
		normal.forEach(card => index.push({type: "count", component: renderBoardCountCell(b, card, i + 1, index.length + 1)}));
		boardIndices[i] = index;
	})

	return <div className="flex-column">
		<S.Table>
			<S.Col width={25} />
			<S.Col width={200} />
			{
				boardTitles.map((item, index) => <S.Col width={80} key={index}>
					{ item.component }
				</S.Col>)
			}
			<S.Col width={100}>
				<NewBoardCell
					boards={boards}
					onNew={async board => {
						const newBoardId = await addBoard(deck, board)
						setFocusBoardId(newBoardId);
					}}
				/>
			</S.Col>
		</S.Table>
		<div className="flex">
			<Scrollbars>
				<S.Table className="flex">
					<S.Col width={25} />
					<S.Col width={200}>
						{
							index.map((item, index) => <Fragment key={index}>
								{ item.component }
							</Fragment>)
						}
					</S.Col>
					{
						boardIndices.map((bi, i) => <S.Col width={80} key={i}>
							{
								bi.map((item, index) => <Fragment key={index}>
										{ item.component }
									</Fragment>)
							}
						</S.Col>)
					}
					<S.Col width={100} />
				</S.Table>
				<div className="space-24" />
			</Scrollbars>
		</div>
		<DeckStatsTable
			deck={deck}
			stats={stats}
			boards={boards}
			inheritedCardsMaps={inheritedCardsMaps}
		/>
	</div>
}

const NumberCell = ({number, className}) => <Cell className={className}>
	<S.CellNumber>{number || 0}</S.CellNumber>
</Cell>

const StatCell = ({stat, cards}) => {

	const count = getCardsStatCount(cards, stat);

	return <NumberCell number={count} />
}

const NewStatButton = ({deck, stats}) => {
	const [ open, setOpen ] = useState(false);
	return <div className="relative">
		<S.CellButton
			className="flex-container"
			onMouseDown={open ? null : () => setOpen(true)}
		>
			<S.CellButtonIcon icon="add" />
			<span className="no-wrap">Statistic</span>
		</S.CellButton>
		<S.StatCreatorPopup
			pointerEvents
			blockingGroup="stat-creator"
			open={open}
			onClose={() => setOpen(false)}
		>
			<StatCreator
				onStat={stat => {
					addStat(deck, stat);
					setOpen(false);
				}}
				onClose={() => setOpen(false)}
				blockingGroup="stat-creator"
			/>
		</S.StatCreatorPopup>
	</div>
}

const DeckStatsTable = ({deck, stats, boards, inheritedCardsMaps}) => {

	const totalCount = getBoardsCardsCount(boards);

	const format = formats[deck?.format];

	const boardIndices = [];
	boards?.forEach(b => {
		const index = [];
		const cards = getNormalCards(Object.values(inheritedCardsMaps[b.id] || {}));
		index.push({ type: "empty", component: <NumberCell
				className="semibold"
				number={getCardsCount(cards)}
			/> });
		stats?.forEach(s => {
			index.push({type: "stat", component: <StatCell stat={s} cards={cards} />})
		});
		boardIndices.push(index);
	});

	return <>
		<hr className="margin-0" />
		<S.Table>
			<S.Col className="relative" width={125}>
				<h2 className="absolute-center no-wrap margin-0">{`${totalCount} / ${format?.total || 0}`}</h2>
			</S.Col>
			<S.Col width={100}>
				<SectionTitleCell name="Totals" />
				{
					stats?.map((s, i) => <StatTitleCell
							key={i}
							deck={deck}
							stat={s}
							{...s}
						/>)
				}
				<NewStatButton deck={deck} stats={stats} />
			</S.Col>
			{
				boardIndices.map((bi, i) => <S.Col width={80} key={i}>
				{
					bi.map((item, index) => <Fragment key={index}>
							{ item.component }
						</Fragment>)
				}
				</S.Col>)
			}
		</S.Table>
	</>
}



export default DeckTable;