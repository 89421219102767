import { initializeApp } from "firebase/app";

const firebaseConfig = {
	apiKey: "AIzaSyD2yHyh1NWcuY0UCd3CmdSEtB1mHAk1KWs",
	authDomain: "korshem-fab.firebaseapp.com",
	projectId: "korshem-fab",
	storageBucket: "korshem-fab.appspot.com",
	messagingSenderId: "191193628448",
	appId: "1:191193628448:web:a19293b8c73538ee14e145",
	measurementId: "G-G32K4H4MPB"
};

const app = initializeApp(firebaseConfig);

export default app;