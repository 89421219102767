import { useMemo } from "react";
import Dropdown from "./Dropdown";
import StandardDropdownOption from "./StandardDropdownOption";

const defaultEmpty = {
	label: "Nothing selected"
}

const StandardDropdown = props => {

	const { value, options, closeOnClick=true, onChange, empty=defaultEmpty, theme } = props;

	const optionKeys = useMemo(() => Object.keys(options), [options]);

	return <Dropdown
		{...props}
		options={optionKeys}
	>
		{
			(op, i, { onClose, isDefault }) => {
				if(!op){
					return <StandardDropdownOption
						key={i}
						{...empty}
						isDefault={isDefault}
						theme={theme}
					/>
				}
				return <StandardDropdownOption
					key={i}
					{...options[op]}
					theme={theme}
					isDefault={isDefault}
					selected={value && value === op}
					onMouseDown={evt => {
						if(closeOnClick){
							evt.preventDefault();
							evt.stopPropagation();
						}
						if(onChange){
							onChange(op, evt)
						}
						if(closeOnClick){
							onClose(evt);
						}
					}}
				/>
			}
		}
	</Dropdown>
}

export default StandardDropdown;