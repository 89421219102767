import styled from "styled-components";
import { Card } from "./../components";
import useBounds from "./../hooks/useBounds";
import { useMemo } from "react";
import { getCardTitleHeight, getCardHeight } from "./../utils/CardUtils";
import { getBoardsUniqueCards, sortCards, getNormalCards, getCardStacks } from "./../utils/DeckUtils";
import { Scrollbars } from "react-custom-scrollbars";

const CARD_WIDTH = 200;
const CARD_HEIGHT = getCardHeight(CARD_WIDTH);
const CARD_TITLE_HEIGHT = getCardTitleHeight(CARD_WIDTH);
const CARD_MIN_PADDING = 12;
const STACK_VERT_MARGIN = 24;

const S = {
	Root: styled.div`
		padding: 0px 24px;
	`,
	Container: styled.div`
		display: flex;
		justify-content: space-around;
	`,
	RowContainer: styled.div`
		width: ${CARD_WIDTH};
		position: relative;
	`,
	CardGroupContainer: styled.div`
		width: ${CARD_WIDTH};
		margin-bottom: ${STACK_VERT_MARGIN}px;
		position: relative;
	`,
	CardContainer: styled.div`
		width: ${CARD_WIDTH};
		height: ${({isDisplay}) => isDisplay ? getCardHeight(CARD_WIDTH) : getCardTitleHeight(CARD_WIDTH)}px;
	`,
}

const DeckCardStack = ({className, deck, boards, stats}) => {

	const cards = getBoardsUniqueCards(boards, true);
	const normal = sortCards(getNormalCards(cards));
	const [ bounds, boundsRef ] = useBounds();

	const cardStacks = getCardStacks(normal)

	const rows = useMemo(() => {
		const rows = [];
		const rowCount = Math.floor((bounds.width + CARD_MIN_PADDING) / (CARD_WIDTH + CARD_MIN_PADDING));
		for(let i = 0; i<rowCount; i++){
			rows.push([]);
		}
		const toPlace = Object.values(cardStacks);
		while(toPlace.length && rows.length){
			let shortestRowIndex = 0;
			let shortedRowHeight = 999999;
			for(let i = 0; i<rows.length; i++){
				const h = rows[i].reduce((prev, cur) => prev
					+ (
						// Get card count, remove one for the full card height
						Object.values(cur).reduce((prev, cur) => prev + cur.count, 0) -1
					) * CARD_TITLE_HEIGHT // Multiply that count by the small height
					+ CARD_HEIGHT // Add back on the full card height
					+ STACK_VERT_MARGIN, // Add the vertical margin for the stack
				0);
				if(h < shortedRowHeight){
					shortedRowHeight = h;
					shortestRowIndex = i;
				}
			}
			rows[shortestRowIndex].push(toPlace.shift());
		}
		return rows;
	}, [ cardStacks, bounds?.width ]);

	return <S.Root className={className}>
		<Scrollbars>
			<S.Container ref={boundsRef}>
				{
					rows.map((r, i) => <S.RowContainer key={i}>
						{
							r.map((stack, i2) => <S.CardGroupContainer key={i2}>
								{
									Object.values(stack).map((c, i3) => {
										const cards = [];
										for(let i = 0; i<c.count; i++){
											cards.push(<S.CardContainer
												isDisplay={i3 === Object.keys(stack).length - 1 && i === c.count - 1}
												key={i}
											>
												<Card card={c} />
											</S.CardContainer>)
										}
										return <div key={i3}>
											{ cards }
										</div>
									})
								}
							</S.CardGroupContainer>)
						}
					</S.RowContainer>)
				}
			</S.Container>
		</Scrollbars>
	</S.Root>

}

export default DeckCardStack;