import styled from "styled-components/macro";
import { CSSTransition } from "react-transition-group"
import { useRef, forwardRef, useCallback } from "react";

const S = {
	Container: styled.div`

		${({open, at, ao}) => open ? at : ao}

		&.ac-enter, &.ac-appear {
			${({ai}) => ai}
		}

		&.ac-enter-active, &.ac-appear-active {
			transition: ${({tri}) => tri};
			${({at}) => at}
		}

		&.ac-enter-done {
			${({at}) => at}
		}

		&.ac-exit {
			${({at}) => at}
		}

		&.ac-exit-active {
			transition: ${({tro}) => tro};
			${({ao}) => ao}
		}

		&.ac-exit-done {
			${({ao}) => ao}
		}

	`
}

const animationParameters = (
	transformIn,
	transformOut,
	durationIn,
	durationOut,
	delayIn,
	delayOut
) => ({
animateIn: `opacity: 0; transform: translate(${transformIn[0]}px, ${transformIn[1]}px); pointer-events: none;`,
animateTo: `opacity: 1; transform: translate(0px, 0px);`,
animateOut: `opacity: 0; transform: translate(${transformOut[0]}px, ${transformOut[1]}px); pointer-events: none;`,
transitionIn: `opacity ${durationIn}ms ${delayIn}ms, transform ${durationIn}ms ${delayIn}ms`,
transitionOut: `opacity ${durationOut}ms ${delayOut}ms, transform ${durationOut}ms ${delayOut}ms`,
})

const commonAnimations = {
	up: 			[ 0, 20 ],
	down: 			[ 0, -20 ],
	left: 			[ 20, 0 ],
	right: 			[ -20, 0 ],
	fade: 			[ 0, 0 ],
	"up-small": 	[ 0, 6 ],
	"down-small": 	[ 0, -6 ],
	"left-small": 	[ 6, 0 ],
	"right-small": 	[ -6, 0 ],
}

const defaultDuration = 300;
const defaultDurations = {
	"up-small": 150,
	"down-small": 150,
	"left-small": 150,
	"right-small": 150,
}

const AnimatedContainer = forwardRef(({
	open,
	appear,
	duration,
	durationIn,
	durationOut,
	delayIn=0,
	delayOut=0,
	style,
	animation,
	animationIn="up",
	animationOut="up",
	unmountOnExit=true,
	mountOnEnter=true,
	className,
	children,
	onExited,
	onClick,
	onMouseEnter,
	onMouseLeave,
	onTransitioning,
}, ref) => {

	const nodeRef = useRef();

	durationIn = durationIn || duration || defaultDurations[animation] || defaultDuration;
	durationOut = durationOut || duration || defaultDurations[animation] || defaultDuration;

	const { animateIn, animateTo, animateOut, transitionIn, transitionOut } = animationParameters(
		commonAnimations[animation || animationIn],
		commonAnimations[animation || animationOut],
		durationIn, durationOut,
		delayIn, delayOut
	);

	const handleRef = useCallback(r => {
		nodeRef.current = r;
		if(ref){
			ref.current = r;
		}
	}, [ ref ]);

	return <CSSTransition
		in={!!open}
		timeout={Math.max(durationIn, durationOut) + Math.max(delayIn, delayOut)}
		unmountOnExit={unmountOnExit}
		mountOnEnter={mountOnEnter}
		nodeRef={nodeRef}
		classNames={`ac`}
		onEnter={onTransitioning ? () => onTransitioning(true) : null}
		onEntered={onTransitioning ? () => onTransitioning(false) : null}
		onExit={onTransitioning ? () => onTransitioning(true) : null}
		onExited={onTransitioning || onExited ? evt => {
			if(onTransitioning){
				onTransitioning(false)
			}
			if(onExited){
				onExited(evt)
			}
		} : null}
		appear={appear}
	>
		<S.Container
			ref={handleRef}
			style={style}
			className={className}
			ai={animateIn}
			at={animateTo}
			ao={animateOut}
			tri={transitionIn}
			tro={transitionOut}
			open={open}
			onClick={onClick}
			onMouseEnter={onMouseEnter}
			onMouseLeave={onMouseLeave}
		>
			{ children }
		</S.Container>
	</CSSTransition>

})

export default AnimatedContainer;