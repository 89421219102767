import styled from "styled-components/macro";
import LoadDiv from "./LoadDiv";
import { cardDimensionGetters, getCardSmallestImageSource } from "./../utils/CardUtils";

const S = {
	Container: styled.div`
		background-color: #828282;
		position: relative;
		overflow: hidden;
	`
}

const Card = ({
	card,
	width:w=200,
	className,
	loading,
	dimensions="full"
}) => {

	const { x, y, width, height, containerWidth, containerHeight, borderRadius } = cardDimensionGetters[dimensions](w);

	const imageSource = getCardSmallestImageSource(card, width);

	return <S.Container className={className} style={{
			width: containerWidth,
			height: containerHeight,
			borderRadius
		}}
	>
		{
			card?.images ? <img
				src={imageSource}
				alt={card?.name}
				style={{
					position: "absolute",
					left: `${x}px`,
					top: `${y}px`,
					width,
					height
				}}
			/> : null
		}
		{
			loading ? <LoadDiv className="expand" open /> : null
		}
	</S.Container>

}

export default Card;