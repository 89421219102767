import styled, { css } from "styled-components/macro";
import classnames from "classnames";
import Icon from "./Icon";

const S = {
	Container: styled.div`
		display: flex;
		align-items: center;
		
		border-radius: 2px;
		width: fit-content;
		padding: ${({hasLabel}) => hasLabel ?  "12px 12px" : "6px 6px"};

		cursor: pointer;

		background-color: transparent;

		&:hover {
			background-color: rgba(0, 0, 0, 0.1);
		}

		${({isDisabled}) => isDisabled && css`
			cursor: auto;
			opacity: 0.8;
		`}
		position: relative;

		font-weight: var(--font-weight-semibold);

		fill: white;

		&.no-padding {
			padding: 0px;
		}

		&.simple {
			background-color: transparent;
		}

		&.cta {
			${({isDisabled}) => isDisabled ? css`
				background-color: #888;
			` : css`
				background-color: var(--color-cta);
				box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.1);
			`}
			font-weight: var(--font-weight-bold);
		}

		&.small {
			font-size: var(--font-size-medium);
			font-weight: var(--font-weight-semibold);
			padding: 6px 6px;
		}
	`,
	Label: styled.span`
	`
}

const Button = props => {

	const {
		className,
		icon,
		iconClassName,
		label,
		disabled,
		children,
		href,
		alt,
		onClick
	} = props;

	return <S.Container
		{...props}
		as={href ? "a" : "button"}
		onClick={disabled ? null : onClick}
		isDisabled={disabled}
		autoOpacity={false}
		hasLabel={!!label}
		className={className}
	>
		<div className={classnames("flex flex-container-centered overflow-hidden", {"auto-opacity": !disabled})}>
			{
				icon ? <Icon
					className={classnames("icon", iconClassName)}
					icon={icon}
					alt={alt}
				/> : null
			}
			{
				label && typeof(label) === "string" ? <S.Label>{label}</S.Label> : label
			}
		</div>
		{ children }
	</S.Container>

}

export default Button;