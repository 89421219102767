// import { ResizeObserver } from '@juggle/resize-observer'
// import useMeasure from 'react-use-measure'

// const useBounds = (externalRef, threshold=0, interval=50) => {

// 	const [ ref, bounds ] = useMeasure({ polyfill: ResizeObserver });

// 	return [ bounds, ref ]

// }

import {
	useRef,
	useState,
	// useEffect,
	useCallback
} from "react";
import useInterval from "./../hooks/useInterval";

const useBounds = (externalRef, threshold=0, interval=50) => {

	const ref = useRef();
	const [ bounds, setBounds ] = useState({});

	useInterval(useCallback(() => {
		const element = externalRef?.current || ref?.current;
		if(element){
			const b = element.getBoundingClientRect();
			if(threshold){
				if(
					Math.abs(b.x - bounds.x) > threshold ||
					Math.abs(b.y - bounds.y) > threshold ||
					Math.abs(b.width - bounds.width) > threshold ||
					Math.abs(b.height - bounds.height) > threshold
				){
					setBounds(b);
				}
			} else if(
				b.x !== bounds.x ||
				b.y !== bounds.y ||
				b.width !== bounds.width ||
				b.height !== bounds.height
			){
				setBounds(b);
			}
		}
	}, [bounds, threshold, externalRef]), interval);

	return [ bounds, ref ]

}

export default useBounds;