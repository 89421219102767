/*

{
	name: "Phantasm",
	color: "#ff0000",
	condition: {
		type: "group",
		operator: "or",
		value: [
			{ type: "power", operator: ">=", value: 6 },
			{ type: "card", operator: "=", value: "lightning-press-red" },
			{ type: "card", operator: "=", value: "lightning-press-yellow" },
		]
	}
}

*/

let cardMeetsCondition;

cardMeetsCondition = (card, condition) => {
	const { type, operator, value } = condition;
	switch(type){
		case "power":
		case "pitch":
		case "cost":
		case "id":
		case "defense":
			switch(operator) {
				case ">=": return card[type] >= value;
				case ">": return card[type] > value;
				case "<=": return card[type] <= value;
				case "<": return card[type] > value;
				case "=": return card[type] === value;
				case "!=": return card[type] !== value;
				default:
					console.error(`Unhandled card condition operator ${operator}`);
					return false;
			}
		case "types":
		case "classes":
		case "subtypes":
		case "talents":
			switch(operator){
				case "=": return card[type]?.includes(value);
				case "!=": return !card[type]?.includes(value);
				case "includes": return card[type]?.includes(value);
				case "excludes": return !card[type]?.includes(value);
				default:
					console.error(`Unhandled card condition talent operator ${operator}`);
					return false;
			}
		case "group":
			switch(operator){
				case "and":
					for(let i = 0; i<value.length; i++){
						if(!cardMeetsCondition(card, value[i])){
							return false;
						}
					}
					return true;
				case "or":
					for(let i = 0; i<value.length; i++){
						if(cardMeetsCondition(card, value[i])){
							return true;
						}
					}
					return false;
				default:
					console.error(`Unhandled card condition group operator ${operator}`);
					return false;
			}
		default:
			console.error(`Unhandled card condition type ${type}`);	
			return false;
	}
}


export const getCardsStatCount = (cards, stat) => {
	return cards.reduce(
		(prev, cur) => (prev || 0) + (cardMeetsCondition(cur, stat.condition) ? cur.count : 0),
		0
	);
}

const maxPower = 14;
const maxDef = 7;
const numbers = [];
for(let i = 0; i<Math.max(maxPower, maxDef); i++){
	numbers[i] = i;
}

let conditionToAlgoliaQuery;
conditionToAlgoliaQuery = (condition) => {
	if(!condition){
		return "";
	}
	const { type, operator, value } = condition;
	switch(type){
		case "power":
		case "pitch":
		case "cost":
		case "type":
		case "id":
		case "class":
		case "defense":
			switch(operator) {
				case ">=": return numbers.slice(value).map(n => `${type}:${n}`).join(" OR ");
				case ">": return numbers.slice(value + 1).map(n => `${type}:${n}`).join(" OR ");
				case "<=": return numbers.slice(0, value + 1).map(n => `${type}:${n}`).join(" OR ");
				case "<": return numbers.slice(0, value).map(n => `${type}:${n}`).join(" OR ");
				case "=": return `${type}:${value}`;
				case "!=": return `NOT ${type}:${value}`;
				default:
					console.error(`Unhandled card condition operator ${type}/${operator} (algolia)`);
					return false;
			}
		case "subtypes":
		case "talents":
			switch(operator){
				case "includes": return `${type}:${value}`;
				case "excludes": return `NOT ${type}:${value}`;
				default:
					console.error(`Unhandled card condition operator ${type}/${operator} (algolia)`);
					return false;
			}
		case "group":
			if(value.length === 0){
				return "";
			}
			switch(operator){
				case "and": return `(${value.map(con => conditionToAlgoliaQuery(con)).join(" AND ")})`;
				case "or": return `(${value.map(con => conditionToAlgoliaQuery(con)).join(" OR ")})`;
				default:
					console.error(`Unhandled card condition group operator ${operator} (algolia)`);
					return false;
			}
		default:
			console.error(`Unhandled card condition type ${type} (algolia)`);	
			return false;
	}
}


export const getAlgoliaQuery = condition => conditionToAlgoliaQuery(condition);

