import dayjs from "dayjs";

export const clampCardLimit = (format, card, count) => {
	if(!format){
		return 0;
	}
	if(card.banned?.includes(format.id)){
		return 0
	}
	if(card.legendary){
		return Math.min(count, 1);
	} else {
		return Math.min(format.standardCardLimit, Math.max(0, count));	
	}
}

export const cardTotalValid = (format, card, count) => {
	if(!format){
		return false;
	}
	if(card.banned?.includes(format.id)){
		return count === 0;
	}
	if(card.legendary && count > 1){
		return false;
	}
	if(count < 0){
		return false;
	}
	return count <= format.standardCardLimit;
}

export const getCardStacks = (cards) => {
	const ret = {};
	cards.forEach(c => {
		if(c.count > 0){
			const baseId = c.id.split(/-(red|yellow|blue)$/)[0];
			if(!ret[baseId]){
				ret[baseId] = {};
			}
			ret[baseId][c.pitch] = c;
		}
	});
	return ret;
}

export const getBoardsUniqueCards = (boards, hydrated) => {
	const ret = {};
	boards?.forEach(b => b.cards.forEach(c => ret[c.id] = c));
	return hydrated ? Object.values(ret) : Object.keys(ret);
}

export const getBoardsMaxCardCounts = (boards) => {
	const ret = {};
	boards?.forEach(b => b.cards.forEach(c => {
		if(!ret[c.id] || c.count > ret[c.id]){
			ret[c.id] = c.count;
		}
	}));
	return ret;
}

export const getCardsCount = cards => cards.reduce((prev, card) => prev + card.count, 0);

export const getBoardsCardsCount = boards => {
	const counts = getBoardsMaxCardCounts(boards || []);
	return Object.values(counts).reduce((prev, count) => prev + count, 0);
}

const sortMethods = {
	order: (o1, o2, dir) => ((o1.order || 0) - (o2.order || 0)) * dir,
	createdAt: (o1, o2, dir) => dayjs(o1.createdAt?.toDate()).isAfter(dayjs(o2.createdAt?.toDate())) ? dir : -dir,
	alpha: (c1, c2, dir) => c1.name === c2.name ? 0 : c1.name < c2.name ? dir : -dir,
	pitch: (c1, c2, dir) => ((c2.pitch || 0) - (c1.pitch || 0)) * dir,
}
const sortDirections = {
	ascending: 1,
	descending: -1,
}

const sortObjects = (objects, orderMethod, direction) => {
	const components = orderMethod.split("-");
	return objects?.slice().sort((c1, c2) => {
		for(let i = 0; i<components.length; i++){
			const r = sortMethods[components[i]](c1, c2, sortDirections[direction]);
			if(r){
				return r;
			}
		}
		return 0;
	});
}

export const sortCards = (cards, orderMethod="alpha-pitch", direction="descending") => {
	return sortObjects(cards, orderMethod, direction);
}

export const sortBoards = (boards, orderMethod="order-createdAt", direction="descending") => {
	return sortObjects(boards, orderMethod, direction);
}

export const sortDecks = (decks, orderMethod="order-createdAt", direction="descending") => {
	return sortObjects(decks, orderMethod, direction);
}

export const getNormalCards = cards => cards.filter(c => !c.types?.includes("weapon") && !c.types?.includes("equipment"));

export const getWeapons = cards => cards.filter(c => c.types?.includes("weapon"));

export const getEquipment = cards => cards.filter(c => c.types?.includes("equipment"));

export const tryGetInheritedCardsMap = (board, boards) => {
	const boardTrace = [];
	const ret = {};
	let boardId = board?.id;
	while(boardId){
		let toAdd = null;
		for(let i = 0; i<boards.length; i++){
			if(boards[i].id === boardId){
				toAdd = boards[i];
				break;
			}
		}
		if(!toAdd){
			const err = new Error("board-missing");
			err.boardTrace = boardTrace;
			throw err;
		}
		if(boardTrace.includes(toAdd)){
			const err = new Error("cyclic-tracking");
			err.boardTrace = boardTrace;
			throw err;
		}
		boardTrace.push(toAdd);
		toAdd.cards.forEach(c => {
			if(!ret[c.id]){
				ret[c.id] = Object.assign({}, c);
			}
		});
		boardId = toAdd.trackedBoardId;
	}
	return ret;
}



