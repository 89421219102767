import { useRef, useCallback, useEffect } from 'react'

const Draggable = ({className, disabled, onDrag, onDragEnd, onDragBegin, children}) =>  {

	// const [ held, setHeld ] = useState(false);

	const heldRef = useRef(false);
	const startPositionRef = useRef({x: -1, y: -1});
	const lastReportedDragRef = useRef({x: -1, y: -1});

	const handleMouseMove = useCallback(evt => {
		if (heldRef.current) {
			evt.preventDefault()
			let x = evt.clientX
			let y = evt.clientY
			onDrag({
				x,
				y,
				dx: lastReportedDragRef.x - x,
				dy: lastReportedDragRef.y - y,
				startX: startPositionRef.x,
				startY: startPositionRef.y,
			}, evt)
			lastReportedDragRef.current = { x, y };
		}
	}, [ onDrag ])

	const handleMouseUp = useCallback(evt => {
		if(heldRef.current){
			lastReportedDragRef.current = { x: -1, y: -1 };
			if(onDragEnd){
				onDragEnd(evt)
			}
			heldRef.current = false;
		}
	}, [ onDragEnd ])

	const handleMouseDown = useCallback(evt => {
		if(!disabled){
			let x = evt.nativeEvent.clientX
			let y = evt.nativeEvent.clientY
			startPositionRef.current = { x, y }
			lastReportedDragRef.current = { x, y };
			if(onDragBegin){
				onDragBegin({x, y});
			}
			heldRef.current = true;
		}
	}, [ onDragBegin, disabled ]);

	useEffect(() => {
		window.addEventListener('mousemove', handleMouseMove, true)
		window.addEventListener('mouseup', handleMouseUp, true)
		return () => {
			window.removeEventListener('mousemove', handleMouseMove, true)
			window.removeEventListener('mouseup', handleMouseUp, true)
		}
	}, [ handleMouseMove, handleMouseUp ]);

	return <div
		onMouseDown={handleMouseDown}
		className={className}
	>
		{ children }
	</div>
}

export default Draggable
