import styled from "styled-components/macro";
import classnames from "classnames";
import Icon from './Icon';

const S = {
	Container: styled.div`
		display: inline-flex;
		width: ${({size}) => size * 1.6}px;
		height: ${({size}) => size}px;
		position: relative;
		cursor: pointer;
		flex-shrink: 0;

		fill: var(--color-inactive);

		.on {
			fill: var(--color-cta);
		}
	`,
	Fill: styled.div`
		position: absolute;
		top: 0px;
		left: 0px;
		width: 100%;
		height: 100%;
		border-radius: ${({size}) => size * 0.5}px;

		background-color: var(--color-inactive);
		transition: background-color 300ms;

		.on & {
			background-color: var(--color-cta)
		}
	`,
	Handle: styled.div`
		position: absolute;
		top: ${({border}) => border}px;
		left: ${({border}) => border}px;
		width: ${({size, border}) => size - border * 2}px;
		height: ${({size, border}) => size - border * 2}px;
		transform: translateX(0px);
		border-radius: ${({size, border}) => (size - border * 2) * 0.5}px;
		background-color: white;
		transition: transform 300ms;

		.on & {
			transform: translateX(${({size, border}) => (size * 1.6 - border * 2) - (size - border * 2)}px);
		}
	`
}

const Toggle = ({className, icon, value, onChange, size=18, border=1}) => {
	return <S.Container
		size={size}
		onClick={() => onChange(!value)} className={classnames(className, {on: !!value})}
	>
		<S.Fill size={size} />
		<S.Handle size={size} border={border}>
			{
				icon ? <Icon className="expand" icon={icon} /> : null
			}
		</S.Handle>
	</S.Container>

}

export default Toggle;