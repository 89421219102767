import { useEffect } from "react";

const keyCodeKeys = {
	tab: 9
}

let keyIid = 1;
const keyBlocking = {};

const useKey = (key, callback, blocking, event="keydown") => {

	useEffect(() => {

		if(!key || !callback){
			return null;
		}

		const keys = Array.isArray(key) ? key : [ key ];

		const id = keyIid++;

		if(blocking){
			keys.forEach(k => {
				if(!keyBlocking[k]){
					keyBlocking[k] = {}
				}
				keyBlocking[k][id] = id;
			});
		}

		const isBlocked = k => {
			if(keyBlocking[k]){
				const blockingIds = Object.values(keyBlocking[k]);
				for(let i = 0; i<blockingIds.length; i++){
					if(blockingIds[i] !== id && blockingIds[i] > id){
						return true;
					}
				}
			}
			return false;
		}

		const handleDown = evt => {

			let firing = [];

			keys.forEach(k => {
				let keyCode = keyCodeKeys[k];

				if(keyCode){
					if(evt.keyCode === keyCode && !isBlocked(k)){
						firing.push(k)
					}
				} else {
					if(evt.key === k && !isBlocked(k)){
						firing.push(k)
					}
				}
			});

			if(firing.length > 0){
				callback(evt, Array.isArray(key) ? firing : firing[0]);
			}

		}

		window.addEventListener(event, handleDown, false);
		return () => {
			if(keyBlocking[key] && keyBlocking[key][id]){
				delete(keyBlocking[key][id]);
			}
			window.removeEventListener(event, handleDown, false);
		}
	}, [ key, callback, blocking, event ])

}

export default useKey;