import Input from './Input';
import NumberInput from "./NumberInput";
import Button from "./Button";
import Icon from "./Icon";
import Dropdown from "./Dropdown";
import Popup from "./Popup";
import CardDropdownItem from "./CardDropdownItem";
import CardDropdownList from "./CardDropdownList";
import { useCore, useCard, useCardSearch } from "./../hooks/useDatabase";
import { useRef, useState, useEffect, useMemo } from "react";
import styled, { css } from "styled-components/macro";
import { dataTypeOperators, conditions, operators } from "./../data/conditions";

const S = {
	ConditionContainer: styled.div`
		border-radius: 18px;
		background-color: #606060;
		margin: 2px;
		display: flex;
		align-items: center;
		border: 2px solid transparent;
		width: fit-content;

		${({isSelected}) => isSelected && css`
			background-color: var(--color-interest-selected);
			border-color: var(--color-interest-cta);
		`}

		${({clickable}) => clickable && css`
			cursor: pointer;
			&:hover {
				background-color: var(--color-selection);
			}
		`}
	`,
	ConditionIcon: styled(Icon)`
		width: 36px;
		height: 36px;
	`,
	GroupContainer: styled.div`
		padding: 2px 2px 2px;
	`,
	ConditionType: styled.span`
		${({type, operator}) => {
			switch(type){
				case "group":
					switch(operator){
						case "or":
							return `
								background-color: yellow;
							`
						case "and":
							return `
								background-color: red;
							`
						default:
						return ""
					}
				default:
				return "";
			}
		}}
	`,
	AutofillPopup: styled(Popup)`
		max-height: 200px;
		overflow: scroll;
	`,
	GroupOperator: styled.div`
		padding: 3px 6px;
		font-size: var(--font-size-tiny);
		border-radius: 12px;
		text-align: center;
		z-index: 1;
	`,
	OperatorToggle: styled.div`
		padding: 6px;
		border-radius: 18px;
		font-size: var(--font-size-small);
		color: white;
		cursor: pointer;
		${({operator}) => `background-color: var(--color-${operator});`}
		&:hover {
			opacity: 0.8;
		}
	`,
	TypesDropdown: styled(Dropdown)`
		width: fit-content;
	`,
	OperatorDropdown: styled(Dropdown)`
		width: 40px;
	`,
	ConditionTypeOption: styled.p`
		margin: 0px;
		padding: 9px;
		font-weight: var(--font-weight-semibold);
		cursor: pointer;

		&:hover {
			opacity: 0.8;
		}
	`,
	NumberInput: styled(NumberInput)`
		width: 48px;
	`,
	ConditionValueContainer: styled.div`
		${({isDefault}) => isDefault ? css`
			padding: 9px;
		` : css`
			padding: 6px 9px;
		`}
		cursor: pointer;
		&:hover {
			background-color: var(--color-selection-dark);
		}
	`
}
S.OperatorOption = styled(S.ConditionTypeOption)`
	text-align: center;
`;


let Condition;

const CardLookup = ({value, onChange}) => {
	const [ stagingQuery, setStagingQuery ] = useState("");
	const [ query, setQuery ] = useState("");
	const [ cards ] = useCardSearch(query);
	const [ focused, setFocused ] = useState(false);

	const inputRef = useRef();

	useEffect(() => {
		const timeout = setTimeout(() => setQuery(stagingQuery), 300);
		return () => clearTimeout(timeout);
	}, [ stagingQuery ]);

	const [ card ] = useCard(value);

	return <div className="flex-container-centered relative">
		{
			value ? <>
				<CardDropdownItem
					className="flex"
					card={card}
				/>
				<Button
					icon="close"
					onClick={() => onChange(null)}
				/>
			</> : <>
				<Input
					placeholder="Find card..."
					ref={inputRef}
					value={stagingQuery}
					onChange={setStagingQuery}
					onFocus={() => setFocused(true)}
					onBlur={() => setFocused(false)}
				/>
				<CardDropdownList
					options={cards}
					open={focused}
					onChange={c => {
						onChange(c.id)
						inputRef.current?.blur();
					}}
				/>
			</>
		}
	</div>
}

const coreConditions = [ "talents", "classes", "types", "subtypes", "allyTypes" ];

const CoreConditionValue = ({condition, onChange}) => {

	const [ core ] = useCore(condition.type);
	const options = useMemo(() => Object.keys(core), [ core ]);

	return <Dropdown
		theme="simple"
		options={options}
		value={condition?.value}
		showTriangle={false}
		width={200}
	>
		{
			(id, index, { onClose, isDefault }) => <S.ConditionValueContainer
				className="flex-container-centered"
				onMouseDown={evt => {
					evt.stopPropagation();
					onClose();
					onChange(id)
				}}
			>
				{
					isDefault ? null : <>
							<S.ConditionIcon icon={`condition-${id}`} />
							<div className="space-12 no-shrink" />
						</>
				}
				<p className="margin-0">{core[id]?.name}</p>
			</S.ConditionValueContainer>
		}
	</Dropdown>
}

const ConditionValue = ({condition, onChange}) => {
	if(coreConditions.includes(condition.type)){
		return <CoreConditionValue condition={condition} onChange={onChange} />
	} else if (conditions[condition?.type]?.dataType === "numeric") {
		return <S.NumberInput
			value={condition.value}
			disabled={!onChange}
			blurBehaviour="submit"
			onChange={onChange}
			min={conditions[condition.type].minValue}
			max={conditions[condition.type].maxValue}
		/>
	} else {
		switch(condition?.type){
			case "id":
				return <CardLookup
					value={condition.value}
					disabled={!onChange}
					onChange={onChange}
				/>
			default:
				return <p>?!</p>
		}
		
	}
}

const getConditionIcon = condition => {
	switch(condition?.type){
		case "id":
			return "condition-card";
		case "pitch":
			return `condition-pitch-${condition.value}`;
		case "talents":
		case "classes":
		case "subtypes":
			return `condition-${condition?.value || "unknown"}`;
		default:
			return `condition-${condition?.type || "unknown"}`;
	}
}

const StandardCondition = ({condition, onChange}) => {

	const conditionIcon = getConditionIcon(condition);

	const typeOptions = useMemo(() => Object.keys(conditions), []);

	return <>
		<S.ConditionIcon icon={conditionIcon} />
		<S.TypesDropdown
			theme="simple"
			showTriangle={false}
			options={typeOptions}
			value={condition?.type}
			width={100}
		>
			{
				(type, index, { onClose }) => <S.ConditionTypeOption
					onMouseDown={evt => {
						evt.stopPropagation();
						onClose();
						onChange({
							type,
							operator: dataTypeOperators[conditions[type].dataType][0],
							value: conditions[type].defaultValue
						})
					}}
				>{conditions[type]?.label}</S.ConditionTypeOption>
			}
		</S.TypesDropdown>
		{
			(dataTypeOperators[conditions[condition?.type]?.dataType]?.length || 0) > 1 ? <>
				<S.OperatorDropdown
					theme="simple"
					options={dataTypeOperators[conditions[condition?.type]?.dataType]}
					value={condition?.operator}
					showTriangle={false}
				>
				{
					(operator, index, { onClose }) => <S.OperatorOption
						onMouseDown={evt => {
							evt.stopPropagation();
							onClose();
							onChange(Object.assign({}, condition, { operator }))
						}}
					>{operators[operator].label}</S.OperatorOption>
				}
				</S.OperatorDropdown>
			</> : null
		}
		{
			condition?.type && condition?.operator ? <>
				<ConditionValue
					condition={condition}
					onChange={value => onChange(Object.assign({}, condition, { value }))}
				/>
				<div className="space-6" />
			</> : null
		}
	</>
}

// const GroupCondition = ({condition, onChange, focus}) => {

// 	const [ addOperator, setAddOperator ] = useState(condition?.operator);

// 	return <S.GroupContainer>
// 		{
// 			!condition?.value.length ? <StandardCondition
// 				onChange={v => onChange(Object.assign({}, condition, { value: [ v ] }))}
// 				focus={focus}
// 			/> : <>
// 				{
// 					condition.value.map((c, i) => <div className="relative" key={i}>
// 						<Condition
// 							key={i}
// 							condition={c}
// 							onChange={v => onChange(Object.assign({}, condition, {
// 								value: [
// 									...condition.value.slice(0, i),
// 									v,
// 									...condition.value.slice(i + 1),
// 								]
// 							}))}
// 							onRemove={() => onChange(Object.assign({}, condition, {
// 								value: [
// 									...condition.value.slice(0, i),
// 									...condition.value.slice(i + 1),
// 								]
// 							}))}
// 						/>
// 						{
// 							i < condition.value.length - 1 ? <S.GroupOperator>{condition.operator.toUpperCase()}</S.GroupOperator> : <div className="space-6" />
// 						}
// 					</div>)
// 				}
// 				<div className="flex-container-centered">
// 					<S.OperatorToggle operator={addOperator} onClick={() => setAddOperator(addOperator === "and" ? "or" : "and")}>
// 						<span className="no-wrap">+ {addOperator.toUpperCase()}</span>
// 					</S.OperatorToggle>
// 					<div className="space-6" />
// 					<EmptyCondition
// 						key={condition?.value?.length}
// 						onChange={c => {
// 							if(condition.operator !== addOperator){
// 								onChange({
// 									type: "group",
// 									operator: "or",
// 									value: [ condition, {
// 										type: "group",
// 										operator: addOperator,
// 										value: [
// 											c
// 										]
// 									} ]
// 								})
// 							} else {
// 								onChange(Object.assign({}, condition, { value: [ ...condition.value, c ] }))	
// 							}
// 						}}
// 					/>
// 				</div>
// 			</>
// 		}
// 	</S.GroupContainer>
// }

Condition = ({condition, selected, onClick, onChange, onRemove, focus}) => {

	let InnerComponent;
	switch(condition?.type){
		// case "group":
			// InnerComponent = GroupCondition;
		// break;
		default:
			InnerComponent = StandardCondition;
		break;
	}

	return <S.ConditionContainer
		clickable={!!onClick}
		isSelected={selected}
		type={condition?.type}
		operator={condition?.operator}
		onClick={onClick}
	>
		<InnerComponent focus={focus} condition={condition} onChange={onChange} />
		{
			onRemove ? <>
				<div className='space-6' />
				<span onClick={onRemove}>x</span>
			</> : null
		}
	</S.ConditionContainer>

}

export default Condition;