import useURL from "./../hooks/useURL";
import { useParams } from "react-router-dom";
import { useHydratedDeck, useHydratedDeckBoards, useDeckStats } from './../hooks/useDatabase';
import { DeckTable, DeckCardStack } from "./../layouts";
import { sortBoards } from "./../utils/DeckUtils";
import DeckDetails from "./DeckDetails";
import Library from "./Library";
import { Button, AnimatedContainer } from "./../components";
import styled, { css } from "styled-components";
import { useMemo, useState } from "react";

const S = {
	Container: styled.div`
		overflow: hidden;
		background-color: var(--color-grey-base);
	`,
	Toolbar: styled.div`
		padding: 6px 6px 0px 6px;
		background-color: rgba(0, 0, 0, 0.15);
		display: flex;
	`,
	ContentsContainer: styled.div`
		background: linear-gradient(var(--color-grey-mid) 0%, var(--color-grey-dark) 100px);
	`,
	ToolbarOption: styled.div`
		padding: 12px 24px;
		cursor: pointer;

		border-radius: 4px 4px 0px 0px;

		margin-right: 12px;

		${({selected}) => selected ? css`
			background-color: var(--color-grey-mid);
		` : css`
			background-color: transparent;
		`}

		&:hover {
			opacity: 0.8;
		}
	`,
	SearchAC: styled(AnimatedContainer)`
		position: absolute;
		right: 0px;
		top: 6px;
		bottom: 0px;
		width: 280px;
		background-color: #444856;
		border-radius: 8px 0px 0px 0px;
		padding: 12px;
	`
}

const Deck = () => {

	const { deckId } = useParams();

	const [ deck ] = useHydratedDeck(deckId);
	let [ boards ] = useHydratedDeckBoards(deckId);
	const [ stats ] = useDeckStats(deckId);

	const [ view, setView ] = useURL("v", "table");

	const [ searchOpen, setSearchOpen ] = useState(false);

	boards = useMemo(() => sortBoards(boards, "createdAt", "ascending"), [ boards ]);

	console.log({deck, boards});

	return <S.Container className="flex-container expand relative">
		<DeckDetails
			deck={deck}
			boards={boards}
			stats={stats}
		/>
		<div className="flex flex-column relative">
			<S.Toolbar>
				<S.ToolbarOption
					selected={view === "cards"}
					onClick={() => setView("cards")}
				>
					<h3 className="margin-0">Cards</h3>
				</S.ToolbarOption>
				<S.ToolbarOption
					selected={view === "table"}
					onClick={() => setView("table")}
				>
					<h3 className="margin-0">Table</h3>
				</S.ToolbarOption>
				<div className="flex" />
				<Button
					label="Add Cards"
					className="cta"
					onClick={() => setSearchOpen(true)}
				/>
			</S.Toolbar>
			<S.ContentsContainer className="flex-column flex">
				{
					view === "table" ? <DeckTable
						className="flex"
						deck={deck}
						boards={boards}
						stats={stats}
					/> : null
				}
				{
					view === "cards" ? <DeckCardStack
						className="flex"
						deck={deck}
						boards={boards}
						stats={stats}
					/> : null
				}
			</S.ContentsContainer>
			<S.SearchAC
				open={searchOpen}
				animation="left"
				className="flex-column"
				unmountOnExit={false}
				mountOnEnter={false}
			>
				<div className="flex-container">
					<div className="flex" />
					<Button className="small" onClick={() => setSearchOpen(false)} icon="close" />
				</div>
				<Library
					className="flex"
					deck={deck}
					boards={boards}
					stats={stats}
					boardIndex={0}
				/>
			</S.SearchAC>
		</div>
	</S.Container>
}

export default Deck;