

const Page404 = () => {

	return <div>
		<p>404  ¯\_(ツ)_/¯</p>
	</div>

}

export default Page404;