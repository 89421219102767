import Popup from "./Popup";
import { useState } from "react";
import styled from "styled-components";
import Card from "./Card";
import { shouldPreloadCardImages } from "./../utils/DeviceUtils";

const S = {
	Container: styled.div`
		position: relative;
		cursor: default;
	`,
	Popup: styled(Popup)`
		box-shadow: 2px 2px 12px rgba(0, 0, 0, 0.2);
	`
}

console.log({shouldPreloadCardImages});

const sides = [ "top", "bottom", "left", "right" ];


const CardHint = ({card, children, className, popupProps}) => {

	const [ open, setOpen ] = useState(false);

	return <S.Container
		className={className}
		onMouseEnter={() => setOpen(true)}
		onMouseLeave={() => setOpen(false)}
	>
		{ children }
		<S.Popup
			open={open}
			autoStyle={false}
			duration={100}
			delay={0}
			alwaysMounted={shouldPreloadCardImages}
			sides={sides}
			{ ...(popupProps || {}) }
		>
			<Card card={card} />
		</S.Popup>
	</S.Container>

}

export default CardHint;