import useSingleton from "./../hooks/useSingleton";
import useSharedState, { useSetSharedState } from "./../hooks/useSharedState";
import useStable from "./../hooks/useStable";
import { useCallback } from "react";
import { LoadWheel, Modal, Button } from "./../components";

const useLoader = () => {

	useSingleton("loader", useCallback(() => <LoaderWrapper />, []))
	const setConfig = useSetSharedState("loader");

	const load = useCallback((promise, config) => {
		return new Promise((resolve, reject) => {
			let cancelled = false;
			config = Object.assign({
				title: "Loading...",
				cancellable: false,
			}, config, {
				reject: error => {
					cancelled = true;
					setConfig(null);
					reject(error);
				}
			});
			setConfig(config)
			promise.then(result => {
				if(!cancelled){
					resolve(result);
					setConfig(null);
				}
			})
			.catch(error => {
				if(!cancelled){
					setConfig(null);
					reject(error);
				}
			});
		});
	}, [ setConfig ])

	return load;
}

const LoaderWrapper = () => {
	const [ config, setConfig ] = useSharedState("loader");
	const { allowBackgroundClose, reject } = config || {};

	const handleCancel = useCallback(() => {
		setConfig(null);
		reject(new Error("cancelled"));
	}, [ reject, setConfig ])

	return <Modal
			open={!!config}
			allowBackgroundClose={allowBackgroundClose}
			onClose={allowBackgroundClose && handleCancel}
		>
		<Loader config={config} onCancel={handleCancel} />
	</Modal>
}

const Loader = ({config, onCancel}) => {

	const stableConfig = useStable(config);
	const { title, cancellable } = stableConfig || {};

	return <>
		{ title ? <h2>{title}</h2> : null }
		<LoadWheel />
		{
			cancellable ? <Button
				className="small"
				label="Cancel"
				onClick={onCancel}
			/> : null
		}
	</>
}

export default useLoader;