import { useLocation, useNavigate } from 'react-router-dom'
import { useMemo } from "react";

const useQuery = () => {
	return new URLSearchParams(useLocation().search)
}

const setParamFactory = (navigate, key, type, onChange) => (value, clearOtherParams) => {
	if(!key){
		return null;
	}
	const params = new URLSearchParams(window.location.search)
	if(clearOtherParams){
		let toDelete = [];
		params.forEach((v, k) => toDelete.push(k));
		toDelete.forEach(k => params.delete(k));
	}
	
	if (value === null || value === undefined || value === false) {
		params.delete(key)
	} else {
		switch (type) {
			case 'string':
				value = encodeURIComponent(value);
				break;
			case 'boolean':
				value = value ? "true" : "false";
				break;
			case 'object':
				value = JSON.stringify(value)
				break
			default:
				break
		}
		params.set(key, value)
	}
	if(onChange){
		onChange(value);
	}
	navigate({ search: params.toString() })
}

const defaultValueChecks = {};

export const useSetURL = (arg, type, { onChange }={}) => {
	const navigate = useNavigate()
	return useMemo(() => setParamFactory(navigate, arg, type, onChange), [ navigate, arg, type, onChange ]);
}

const useURL = (arg, defaultValue, type, options={}) => {
	defaultValueChecks[arg] = defaultValue;

	const setURL = useSetURL(arg, type, options);
	const query = useQuery()
	const argPresent = arg && query.has(arg)
	let value = arg ? query.get(arg) : null;
	// console.log({routeMatch, match, value});
	if (argPresent) {
		switch (type) {
			case "string":
				value = decodeURIComponent(value);
				break;
			case 'boolean':
				value = value === "true";
				break;
			case 'integer':
				value = parseInt(value)
				break
			case 'object':
				value = JSON.parse(value)
				break
			default:
				break
		}
	} else {
		value = null
	}

	return [
		value === null || value === undefined ? defaultValue : value,
		setURL,
		// value || previous
	]
}

export default useURL;

// Sending this to localStorage
// export const useExploreConfig = () => useQueryFactory('expl', 'object')
