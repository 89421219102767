import useSharedState, { useSetSharedState } from "./../hooks/useSharedState";
import useSingleton from './../hooks/useSingleton';
import { useState, useCallback, useMemo, Fragment } from "react";
import useStable from "./../hooks/useStable";
import { Modal, Button, Input } from "./../components";
import useKey from "./../hooks/useKey";

const useConfirm = () => {

	useSingleton("confirmer", useCallback(() => <ConfirmerWrapper />, []));

	const setConfirm = useSetSharedState("confirm");

	const confirm = useCallback((details) => {
		return new Promise(resolve => {
			details = Object.assign({
				title: "Are you sure?",
				confirm: "Yes",
				cancel: "Cancel",
				safeguard: null,
				onConfirm: result => {
					resolve(result);
					setConfirm(null);
				},
				onCancel: () => {
					resolve(false);
					setConfirm(null);
				},
				allowSubmit: false,
			}, details);
			setConfirm(details)
		});
	}, [ setConfirm ])


	return confirm;
	
}

const ConfirmerWrapper = () => {

	const [ details ] = useSharedState("confirm");
	const { onCancel } = details || {};

	return <Modal open={!!details} allowBackgroundClose allowEscapeClose onClose={onCancel}>
		<Confirmer details={details} />
	</Modal>

}

const Confirmer = ({details}) => {

	const stableDetails = useStable(details);

	const { title, description, confirm, cancel, onConfirm, onCancel, safeguard, confirmClassName, cancelClassName, allowSubmit } = stableDetails || {};

	useKey(allowSubmit && "Enter", allowSubmit === "cancel" ? onCancel : onConfirm );

	const [ safeguardValue, setSafeguardValue ] = useState("");

	const confirms = useMemo(() => {
		if(typeof(confirm) === "string"){
			return [ { label: confirm, className: confirmClassName, key: true } ]
		}
		if(typeof(confirm) === "object"){
			let ret = [];
			const keys = Object.keys(confirm);
			for(let i =0 ; i<keys.length; i++){
				if(typeof(confirm[keys[i]]) === "object"){
					ret.push(Object.assign({}, confirm[keys[i]], { key: keys[i] }));
				}
				if(typeof(confirm[keys[i]]) === "string"){
					ret.push({ label: confirm[keys[i]], key: keys[i] });
				}
			}
			return ret;
		}
		return [];
	}, [ confirm, confirmClassName ])

	return <>
		{ typeof(title) === "string" ? <h2 className="margin-0">{title}</h2> : title }
		{ title ? <div className="space-12" /> : null }
		{ typeof(description) === "string" ? <p className="large margin-12-0">{description}</p> : description }
		{ description ? <div className="space-12" /> : null }
		{
			safeguard ? <>
				<p className="margin-12-0 medium">Type "{safeguard}" below to continue.</p>
				<Input
					placeholder={`Enter ${safeguard}...`}
					value={safeguardValue}
					onChange={setSafeguardValue}
				/>
				<div className="space-12" />
			</> : null
		}
		<div className="flex-container">
			{
				confirms.map(({ key, label, className }, i) => <Fragment key={i}>
					<Button
						className={className}
						label={label}
						disabled={safeguard && safeguardValue.toLowerCase() !== safeguard.toLowerCase()}
						onClick={() => onConfirm(key)}
					/>
					<div className="space-12" />
				</Fragment>)
			}
			<Button
				className={cancelClassName}
				label={cancel}
				onClick={onCancel}
			/>
		</div>
	</>

}

export default useConfirm;