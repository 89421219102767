import { useEffect, useRef } from 'react'

const defaultCondition = a => !!a;

const useStable = (value, shouldUpdate=defaultCondition) => {
	const ref = useRef();
	useEffect(() => {
		if(value !== ref.current){
			if(typeof(shouldUpdate) === "function"){
				if(shouldUpdate(value, ref.current)){
					ref.current = value
				}
			} else {
				if(shouldUpdate){
					ref.current = value	
				}
			}
		}
	}, [shouldUpdate, value])
	return typeof(shouldUpdate) === "function" ? (value || ref.current) : (shouldUpdate ? value : ref.current);
}

export default useStable
