import { useState, useCallback } from "react";
import Icon from "./Icon";
import DropdownList from "./DropdownList";
import classnames from "classnames";
import styled, { css } from "styled-components/macro";
import useBounds from './../hooks/useBounds';

export const S = {
	Container: styled.div`
		border-radius: 2px;
		background-color: var(--color-grey-light);
		display: flex;
		align-items: center;
		padding: 0px ${({showTriangle}) => showTriangle ? "18px" : "0px"} 0px 0px;
		position: relative;
		cursor: pointer;

		${({disabled}) => disabled && `
			opacity: 0.5;
		`}

		${({theme}) => {
			switch(theme){
				case "simple": return css`
					background-color: transparent;
					&:hover {
						background-color: rgba(0, 0, 0, 0.15);
					}
				`
				default: return "";
			}
		}}
	`,
	Arrow: styled(Icon)`
		position: absolute;
		top: 50%;
		right: 6px;
		transform: translateY(-50%) rotate(0deg);
		transition: transform 300ms;

		width: 12px;
		height: 12px;

		&.open {
			transform: translateY(-50%) rotate(90deg);	
		}
	`,
	Hero: styled.div`
		flex: 1;
		pointer-events: none;
	`
}

const Dropdown = ({
		options,
		onChange,
		className,
		theme,
		value,
		style,
		hint,
		disabledHint,
		disabled,
		children,
		preventDefault,
		width,
		maxHeight,
		blockingGroup,
		showTriangle=true,
	}) => {

	const [ open, setOpen ] = useState(false);
	const valueIndex = options.indexOf(value);

	const [ bounds, boundsRef ] = useBounds();

	const onClose = useCallback(() => setOpen(false), []);

	return <S.Container
		ref={boundsRef}
		disabled={disabled}
		disabledHint={disabledHint}
		onMouseDown={open ? null : evt => {
			if(preventDefault){
				evt.preventDefault();
			}
			setOpen(true);
		}}
		theme={theme}
		className={className}
		showTriangle={showTriangle}
	>
		<S.Hero>
			{ children(value, valueIndex, { onClose, isDefault: true}) }
			{
				showTriangle ? <S.Arrow
					icon="triangle"
					className={classnames(theme, {open})}
				/> : null
			}
		</S.Hero>
		<DropdownList
			style={{
				width: width || `${bounds.width}px`,
			}}
			open={open}
			onChange={onChange}
			onClose={onClose}
			theme={theme}
			options={options}
			selected={value}
			padding={0}
			blockingGroup={blockingGroup}
			entranceDirection="top"
			maxHeight={maxHeight}
		>
			{ children }
		</DropdownList>
	</S.Container>

}

export default Dropdown;