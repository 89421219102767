import styled, { css } from "styled-components/macro";
import { CSSTransition } from "react-transition-group";
import usePortal from "./../hooks/usePortal";
import { Fragment, useRef } from "react";
import useKey from "./../hooks/useKey";
import { Scrollbars } from 'react-custom-scrollbars';

const S = {
	Root: styled.div`
		position: absolute;
		top: 0px;
		left: 0px;
		width: 100%;
		height: 100%;
		transition: opacity 300ms;
		z-index: ${({zIndex}) => zIndex};
		opacity: 0;

		&.modal-enter { opacity: 0; }
		&.modal-enter-active {
			transition: opacity 300ms;
			opacity: 1;
		}
		&.modal-enter-done { opacity: 1; }
		&.modal-exit { opacity: 1; }
		&.modal-exit-active {
			transition: opacity 300ms;
			opacity: 0;
		}
		&.modal-exit-done { opacity: 0; }

		pointer-events: none;
	`,
	Background: styled.div`
		pointer-events: auto;
		position: absolute;
		top: -12px;
		left: 0px;
		width: 100%;
		bottom: -12px;
		background-color: rgba(0, 0, 0, 0.5);
	`,
	Transform: styled.div`
		width: 100%;
		min-height: 100%;
		
		${({scrollable}) => scrollable ? css`
			pointer-events: auto;
			height: auto;
			// display: flex;
			// align-items: center;
			// justify-content: center;
			// height: auto;
			
		` : css`
			position: absolute;
			top: 0px;
			left: 0px;
		`}

		.modal-enter & { transform: translateY(12px); }
		.modal-enter-active & {
			transition: transform 300ms;
			transform: translateY(0px);
		}
		.modal-enter-done & { transform: translateY(0px); }
		.modal-exit & { transform: translateY(0px); }
		.modal-exit-active & {
			transition: transform 500ms;
			transform: translateY(12px);
		}
		.modal-exit-done & { transform: translateY(12px); }
	`,
	Content: styled.div`

		${({autoStyle}) => autoStyle && css`
			border-radius: 4px;
			background-color: var(--color-grey-base);
			box-shadow: 6ps 6ps 12ps rgba(0, 0, 0, 0.25);
			padding: 36px;
			min-width: 40vw;
			max-width: 95vw;
			min-height: 30vh;
		`}
		
		pointer-events: auto;

		${({scrollable}) => scrollable ? css`
			position: relative;
			margin: 96px;
		` : css`
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			max-height: 95vh;
		`}
	`,
	// We have to have *something* on either size of the content div to make sure its
	// margins resolve
	MarginEnforcer: styled.div`
		width: 1px;
		height: 1px;
	`,
	Container: styled.div`
		pointer-events: auto;
		width: 100%;
		height: 100%;
	`
}

const Modal = props => {

	const {
		open,
		zIndex=3,
		nested
	} = props;

	const nodeRef = useRef();
	const inject = usePortal();

	const inner = <CSSTransition
			in={open}
			nodeRef={nodeRef}
			classNames="modal"
			timeout={300}
			unmountOnExit
			mountOnEnter
			appear
		>
			<S.Root zIndex={zIndex} ref={nodeRef}>
				<ModalInner {...props} />
			</S.Root>
		</CSSTransition>

	return nested ? inner : inject(inner);
}

const ModalInner = ({
	noBackground,
	allowBackgroundClose,
	allowEscapeClose,
	onClose,
	children,
	className,
	autoStyle=true,
	scrollable,
}) => {

	useKey(allowEscapeClose && onClose && "Escape", onClose, true);

	const Wrapper = scrollable ? Scrollbars : Fragment;

	return <>
		<S.Container>
			<Wrapper>
				<S.Transform scrollable={scrollable}>
					{
						noBackground ? null : <S.Background onClick={allowBackgroundClose ? onClose : null} />
					}
					<S.MarginEnforcer />
					<S.Content
						scrollable={scrollable}
						className={className}
						autoStyle={autoStyle}
					>
						{ children }
					</S.Content>
					<S.MarginEnforcer />
				</S.Transform>
			</Wrapper>
		</S.Container>
	</>
}

export default Modal;