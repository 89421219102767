import { useState, useEffect, useMemo, useRef } from "react";
import classnames from "classnames";
// import useDimensions from "react-use-dimensions";
import { CSSTransition } from "react-transition-group";
import Mounter from "./Mounter";
import usePortal from "./../hooks/usePortal";
import styled from "styled-components/macro";
import useKey from "./../hooks/useKey";
import useOutsideClickEffect from "./../hooks/useOutsideClickEffect";
import useBounds from "./../hooks/useBounds";


const S = {
	HitDetection: styled.div`
		position: absolute;
		top: 0px; left: 0px; right: 0px; bottom: 0px;
		pointer-events: none;
	`,
	Popup: styled.div`

		transform: translateY(6px);
		opacity: 0;

		&.popup-enter, &.popup-appear {
			transform: translateY(6px);
			opacity: 0;
			will-change: transform, opacity;
		}

		&.popup-enter-active {
			transition: ${({duration}) => `opacity ${duration}ms, transform ${duration}ms`};
			transform: translateY(0px);
			opacity: 1;
		}

		&.popup-enter-done {
			transform: translateY(0px);
			opacity: 1;
		}

		&.popup-exit {
			transform: translateY(0px);
			opacity: 1;
			will-change: transform, opacity;
		}

		&.popup-exit-active {
			transition: ${({duration}) => `opacity ${duration}ms, transform ${duration}ms`};
			transform: translateY(6px);
			opacity: 0;
		}

		&.popup-exit-done {
			transform: translateY(6px);
			opacity: 0;
		}

		&.from-top {
			transform: translateY(6px);
			opacity: 0;
		}

		&.from-top.popup-enter, &.from-top.popup-appear {
			transform: translateY(-6px);
			opacity: 0;
			will-change: transform, opacity;
		}

		&.from-top.popup-enter-active {
			transition: ${({duration}) => `opacity ${duration}ms, transform ${duration}ms`};
			transform: translateY(0px);
			opacity: 1;
		}

		&.from-top.popup-enter-done {
			transform: translateY(0px);
			opacity: 1;
		}

		&.from-top.popup-exit {
			transform: translateY(0px);
			opacity: 1;
			will-change: transform, opacity;
		}

		&.from-top.popup-exit-active {
			transition: ${({duration}) => `opacity ${duration}ms, transform ${duration}ms`};
			transform: translateY(-6px);
			opacity: 0;
		}

		&.from-top.popup-exit-done {
			transform: translateY(-6px);
			opacity: 0;
		}
	`
}


const sidePosition = (side, requiredSpace, positionRect, sizeRect, padding) => {
	switch(side){
		case "top": return positionRect.y - sizeRect.height - padding;
		case "bottom": return positionRect.y + positionRect.height + padding;
		case "left": return positionRect.x - sizeRect.width - padding;
		case "right": return positionRect.x + positionRect.width + padding;
		default: return null;
	}
}

const otherPosition = (chosenSide, requiredSpace, positionRect, sizeRect, padding, alignFrom) => {
	let pos, posDim, sizeDim, winSize;
	switch(chosenSide){
		case "bottom":
		case "top":
			pos = positionRect.x;
			posDim = positionRect.width;
			sizeDim = sizeRect.width;
			winSize = window.innerWidth;
		break;
		case "left":
		case "right":
			pos = positionRect.y;
			posDim = positionRect.height;
			sizeDim = sizeRect.height;
			winSize = window.innerHeight;
		break;
		default: return [ -1, -1 ];
	}

	switch(alignFrom){
		case "start":
		{
			const preferred = Math.max(pos, requiredSpace);
			const shift = Math.max(preferred + sizeDim - (winSize - requiredSpace), 0);
			return [ preferred - shift, shift ];
		}
		case "end":
		{
			const preferred = Math.min(pos + posDim - sizeDim, winSize - requiredSpace);
			const shift = Math.max(-preferred + requiredSpace, 0);
			return [ preferred + shift, shift ];
		}
		default: return [ -1, winSize ];
	}
}

const findSlideAlignment = (directions, requiredSpace, positionRect, sizeRect, padding) => {
	if(!sizeRect.width || !sizeRect.height){
		return { x: 0, y: 0, width: 0, height: 0, uncalculated: true };
	}
	let minFallbackShift = -1;
	let minFallbackArea = -1;
	let fallback = {
		x: positionRect.x,
		y: positionRect.y,
		width: sizeRect.width,
		height: sizeRect.height,
		failed: true,
	};
	for(let i = 0; i<directions.length; i++){
		const { side, alignFrom } = directions[i];
		let x, y, shift;
		switch(side){
			case "top":
			case "bottom":
				[ x, shift ] = otherPosition(side, requiredSpace, positionRect, sizeRect, padding, alignFrom);
				y = sidePosition(side, requiredSpace, positionRect, sizeRect, padding);
			break;
			case "left":
			case "right":
				x = sidePosition(side, requiredSpace, positionRect, sizeRect, padding);
				[ y, shift ] = otherPosition(side, requiredSpace, positionRect, sizeRect, padding, alignFrom);
			break;
			default:
			break;
		}

		const compromisedHeight = (  Math.max(-(y - requiredSpace), 0)  +  Math.max((y + sizeRect.height + requiredSpace) - window.innerHeight, 0)  );
		const compromisedWidth = (  Math.max(-(x - requiredSpace), 0)  +  Math.max((x + sizeRect.width + requiredSpace) - window.innerWidth, 0)  );

		if(shift === 0 && compromisedHeight === 0 && compromisedWidth === 0){
			return { side, x, y, width: sizeRect.width, height: sizeRect.height };
		} else {
			const compromisedArea = compromisedWidth * sizeRect.height + compromisedHeight * sizeRect.width;

			// If we have a fallback that didn't compromise, we compare on shifts
			// Otherwise, we compare on compromised area
			const becomesFallback = minFallbackArea === 0 ? shift < minFallbackShift : (minFallbackArea === -1 || compromisedArea < minFallbackArea)
			if(becomesFallback){
				minFallbackShift = shift;
				minFallbackArea = compromisedArea;
				fallback = { side, x, y, width: sizeRect.width, height: sizeRect.height, shift, compromisedWidth, compromisedHeight, compromisedArea };
			}
		}
	}

	return fallback;
}


const defaultDirections = [
	{ side: 'top', alignFrom: "start" },
	{ side: 'top', alignFrom: "end" },
	{ side: 'bottom', alignFrom: "start" },
	{ side: 'bottom', alignFrom: "end" },
];

const defaultOffset = [ 0, 0 ];

const PopupWrapper = props => {
	const { open, delay, linger, duration=300, alwaysMounted } = props;

	const [ position, positionRef ] = useBounds();

	const [ delayEntered, setDelayEntered ] = useState(false);
	const [ lingering, setLingering ] = useState(false);

	useEffect(() => {
		if(delay){
			if(open){
				const timeout = setTimeout(() => {
					setDelayEntered(true);
				}, delay);
				return () => clearTimeout(timeout);
			} else {
				setDelayEntered(false);
			}
		}
	}, [ delay, open ])

	useEffect(() => {
		if(linger){
			if(open){
				setLingering(true);
			} else {
				const timeout = setTimeout(() => {
					setLingering(false);
				}, linger)
				return () => clearTimeout(timeout);
			}
		}
	}, [open, linger])

	const effectiveOpen = (linger && lingering) || (open && (!delay || delayEntered));

	return <>
		<Mounter
			open={effectiveOpen || alwaysMounted}
			timeout={duration}
		>
			<Popup
				{...props}
				open={effectiveOpen}
				position={position}
				mountOnEnter={!alwaysMounted}
				unmountOnExit={!alwaysMounted}
			/>
		</Mounter>
		<div className="popup-position" ref={positionRef} style={{
				position: "absolute",
				top: "0px", left: "0px", right: "0px", bottom: "0px",
				pointerEvents: "none",
			}}
		/>
	</>
}


const Popup = ({
	open,
	className,
	children,
	style,
	onClose,
	scrollable,
	autoStyle=true,
	entranceDirection="bottom",
	requiredSpace=6,
	duration=300,
	pointerEvents="none",
	zIndex=8,
	padding=6,
	offset=defaultOffset,
	delay=0,
	linger=0,
	position,
	useParentWidth=false,
	allowEscapeClose=true,
	blockingGroup,
	side,
	sides,
	alignFrom,
	directions:externalDirections,
	mountOnEnter=true,
	unmountOnExit=true,
}) => {

	const directions = useMemo(() => {
		if(externalDirections){
			return externalDirections;
		}
		if (side){
			if(alignFrom){
				return [ { side, alignFrom } ]
			} else {
				return [ { side, alignFrom: "start" }, { side, alignFrom: "end" } ]
			}
		}
		if(sides){
			let ret = [];
			sides.forEach(side => ret = [...ret, {side, alignFrom: "start"}, {side, alignFrom: "end"}]);
			return ret;
		}
		return defaultDirections;
	}, [ externalDirections, side, sides, alignFrom ])

	// We rely on a remount to reset this
	const [ establishedSide, setEstablishedSide ] = useState(null);

	const [ alignment, setAlignment ] = useState({x: 0, y: 0, width: 10, height: 10, default: true});
	const [ sizeRect, sizeRef ] = useBounds();
	// const [ sizeRect, sizeRef ] = useBounds();
	
	const nodeRef = useRef();
	const hitDetectRef = useRef();

	const effectiveOpen = open && !alignment.default;

	useKey(effectiveOpen && allowEscapeClose && onClose && "Escape", onClose, true);

	useEffect(() => {
		if(sizeRect && open){
			const a = findSlideAlignment(establishedSide ? directions.filter(d => d.side === establishedSide) : directions, requiredSpace, position, sizeRect, padding);
			// if(a.failed){
			// 	delogger.log("Failed to find valid slide alignment!", {position, sizeRect, directions});
			// }
			if(a.x !== alignment.x || a.y !== alignment.y || a.width !== alignment.width || a.height !== alignment.height){
				// delogger.log("Setting alignment: ", {a, position, sizeRect});
				setEstablishedSide(a.side);
				setAlignment(a);
			}
		}
	}, [ open, establishedSide, requiredSpace, sizeRect, alignment, directions, padding, position, effectiveOpen ]);
	
	useOutsideClickEffect(effectiveOpen && hitDetectRef, onClose, blockingGroup);

	const inject = usePortal();

	return <>
	{
		inject(
			<CSSTransition
				in={effectiveOpen}
				timeout={duration}
				nodeRef={nodeRef}
				classNames="popup"
				appear
				mountOnEnter={mountOnEnter}
				unmountOnExit={unmountOnExit}
			>
				<S.Popup
					className={classnames({"from-top": entranceDirection === "top"})}
					ref={nodeRef}
					duration={duration}
					style={{
						position: "absolute",
						top: "0px",
						left: "0px",
						zIndex,
						pointerEvents: (pointerEvents === "none") || !pointerEvents ? "none" : "auto",
						opacity: alignment.uncalculated ? 0 : null
					}}
				>
					<div ref={sizeRef} className={className} style={Object.assign({
							position: "absolute",
							left: `${alignment.x + offset[0]}px`,
							top: `${alignment.y + offset[1]}px`,
						}, autoStyle && {
							backgroundColor: "var(--color-grey-mid)",
							borderRadius: "4px",
							overflow: "hidden",
							boxShadow: `var(--box-shadow-medium)`,
						},
						style,
						useParentWidth && {
							width: `${position.width}px`,
						})}
					>
						{ children }
						<S.HitDetection ref={hitDetectRef} />
					</div>
				</S.Popup>
			</CSSTransition>
		)
	}
	</>
}

export default PopupWrapper;