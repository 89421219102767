import Popup from './Popup';
import Condition from "./Condition";
import { useCore } from "./../hooks/useDatabase";
import styled, { css } from "styled-components";
import useKey from "./../hooks/useKey";
import { useMemo, useCallback, useState, useEffect } from "react";
import { capitalise, wrapMod } from "./../utils/JSUtils";
import { conditions, dataTypeOperators } from "./../data/conditions";

const S = {
	AutofillPopup: styled(Popup)`
		max-height: 200px;
		overflow-y: auto;
		padding: 0px 6px;
	`,
	EmptyOption: styled.div`
		border-radius: 4px;
		background-color: var(--color-interest);
		padding: 6px 12px;
		border: 2px solid transparent;

		${({isSelected}) => isSelected && css`
			background-color: var(--color-interest-selected);
			border-color: var(--color-interest-cta);
		`}
	`
}

const parseNumericArgument = (query, position) => {
	const components = query.split(" ");
	let v = parseInt(components[position]);
	if(v === null || v === undefined || isNaN(v)){
		return null
	}
	return v;
}

const getParseCondition = type => query => {
	let value = parseNumericArgument(query, 3);
	if(value === null){
		value = parseNumericArgument(query, 2);
	}
	if(value === null){
		value = conditions[type].defaultValue;
	} else {
		value = Math.max(conditions[type].minValue, Math.min(conditions[type].maxValue, value));
	}
	return { type, operator: "=", value };
}

const getPitchOption = (name, value) => ({
	matches: [ name ],
	getCondition: () => ({ type: "pitch", operator: "=", value }),
	getName: () => name,
	getAutocomplete: () => name,
});

const numericParseOptions = [
	{
		matches: [ "Blocks for", "Block for" ],
		getCondition: getParseCondition("defense"),
		getAutocomplete: (query, match, condition) => `${match} ${condition.value}`,
		getName: (query, match, condition) => `${condition.value}-Block`,
	},
	{
		matches: [ "Attacks for", "Attack for" ],
		getCondition: getParseCondition("power"),
		getAutocomplete: (query, match, condition) => `${match} ${condition.value}`,
		getName: (query, match, condition) => `${condition.value}-Power`,
	},
	getPitchOption("Blue", 3),
	getPitchOption("Yellow", 2),
	getPitchOption("Red", 1),
]

const useSuggestedCondition = (query) => {

	const [ talents ] = useCore("talents");
	const [ classes ] = useCore("classes");
	const [ types ] = useCore("types");
	const [ subtypes ] = useCore("subtypes");

	const numericConditions = Object.keys(conditions).filter(k => conditions[k].dataType === "numeric");

	const matches = useMemo(() => {

		const ret = [];
		if(!query.length){
			return ret;
		}
		const appendFromCore = (map, type, operator="=") => {
			if(!map){
				return;
			}
			Object.keys(map).forEach(v => {
				if(v.substring(0, query.length) === query.toLowerCase()){
					ret.push({
						name: capitalise(v),
						condition: { type, operator, value: v }
					});
				}
			})
		}
		appendFromCore(talents, "talents", "includes");
		appendFromCore(classes, "class");
		appendFromCore(subtypes, "subtypes", "includes");
		appendFromCore(types, "type");

		numericParseOptions.forEach(npo => {
			const match = npo.matches.find(m => m.toLowerCase().substring(0, query.length) === query.toLowerCase().substring(0, m.length));
			if(match){
				const condition = npo.getCondition(query);
				if(condition){
					ret.push({
						name: npo.getName(query, match, condition),
						autoComplete: npo.getAutocomplete(query, match, condition),
						condition,
					});
				}
			}
		})

		numericConditions.forEach(type => {
			const terms = query.split(" ");
			let [ search, op, num ] = terms;
			if(type.substring(0, search.length) === search){
				num = parseInt(num);
				const operator = dataTypeOperators[conditions[type].dataType].find(o => o === op) || dataTypeOperators[conditions[type].dataType][0];
				const value = isNaN(num) ? conditions[type].defaultValue : num;
				ret.push({
					name: `${capitalise(type)} ${operator} ${value}`,
					condition: { type, operator, value }
				})
			}
		});
		return ret;
	}, [ numericConditions, talents, classes, subtypes, types, query ]);

	return matches;
}

const ConditionSuggester = ({open, query, onNew, onCondition, onSuggestionName}) => {

	const matches = useSuggestedCondition(query);
	const [ selectedIndex, setSelectedIndex ] = useState(0);

	const [ forceClosed, setForceClosed ] = useState(false);

	useEffect(() => {
		setSelectedIndex(0);
	}, [ matches ]);

	useEffect(() => {
		setForceClosed(false);
	}, [ query ])

	// We add one so we can access the "new" item
	const wrappedSelectedIndex = matches.length ? wrapMod(selectedIndex, matches.length + 1) : 0;

	useKey(query.length && "ArrowUp", useCallback(() => setSelectedIndex(s => s - 1), []));
	useKey(query.length && "ArrowDown", useCallback(() => setSelectedIndex(s => s + 1), []));
	useKey(query.length && "Enter", useCallback(() => {
		if(wrappedSelectedIndex === matches.length){
			onNew()
		} else {
			const m = matches[wrappedSelectedIndex];
			onCondition(m.condition, m.name)
		}
	}, [ onNew, onCondition, wrappedSelectedIndex, matches ]));
	useKey(query.length && "Escape", useCallback(() => setForceClosed(true), [ ]));

	useEffect(() => {
		const s = wrappedSelectedIndex >= 0 && wrappedSelectedIndex < matches.length ? matches[wrappedSelectedIndex] : null;
		onSuggestionName(s?.autoComplete || s?.name);
	}, [ onSuggestionName, wrappedSelectedIndex, matches ]);

	return <S.AutofillPopup
		useParentWidth
		open={open && !!query.length && !forceClosed}
		pointerEvents
		autoStyle={false}
	>
		{
			matches.map((m, i) => <Condition
				key={i}
				condition={m.condition}
				readOnly
				selected={i === wrappedSelectedIndex}
				onClick={() => onCondition(m.condition, m.name)}
			/>)
		}
		<S.EmptyOption
			isSelected={wrappedSelectedIndex === matches.length}
			onClick={onNew}
		>
			<p><i>Create "{query}"</i>...</p>
		</S.EmptyOption>
	</S.AutofillPopup>
}


export default ConditionSuggester;