import Button from "./Button";
import Input from './Input';
import Condition from "./Condition";
import ConditionSuggester from "./ConditionSuggester";
import { conditionValid } from "./../data/conditions";
import { useState, useEffect } from "react";

const StatCreator = ({stat, onStat, onClose}) => {

	const [ condition, setCondition ] = useState({
		type: "id",
		value: "weave-ice-red",
		operator: "=",
	});
	const [ focused, setFocused ] = useState(false);

	useEffect(() => {
		if(stat?.condition){
			setCondition(stat.condition);
		}
	}, [ stat ])

	const [ name, setName ] = useState("");

	const [ creating, setCreating ] = useState(false);
	const [ autoComplete, setAutocomplete ] = useState(null);

	return <div className="relative">
		{
			creating ? <h6>New Statistic</h6> : null
		}
		<Input
			className="flex margin-12-0"
			value={name}
			onChange={setName}
			placeholder={creating ? "Statistic name" : `e.g. "Ice"`}
			focus
			onFocus={() => setFocused(true)}
			onBlur={() => setFocused(false)}
			autoComplete={autoComplete}
		/>
		{
			creating ? <>
				<h6>Condition</h6>
				<Condition
					condition={condition}
					onChange={setCondition}
				/>
				<div className="flex-container-centered">
					<Button
						label="Create"
						className="small cta"
						disabled={!conditionValid(condition)}
						onClick={() => onStat({
							name,
							color: "#4A7596",
							condition,
						})}
					 />
					 <div className="space-12" />
					 <Button
					 	className="small"
					 	label="Cancel"
					 	onClick={onClose}
					  />
				 </div>
			</> : <>
					<ConditionSuggester
						open={focused}
						query={name}
						onSuggestionName={setAutocomplete}
						onCondition={(condition, conditionName) => {
							onStat({
								name: conditionName,
								color: "#4A7596",
								condition,
							})
						}}
						onNew={() => {
							setAutocomplete(null);
							setCreating(true);
						}}
					/>
			</>
		}
	</div>

}

export default StatCreator;