import "./App.css";
import { Header } from "./views";
import AppRoutes from './AppRoutes';
import korshem from "./assets/images/korshem_2400.jpg";
import styled from "styled-components/macro";

const S = {
	Background: styled.img`
		position: absolute;
		top: 0px;
		left: 0px;
		width: 100%;
		height: 100%;
		z-index: 0;
		object-fit: cover;
		object-position: 0% 50%;
	`,
	App: styled.div`
		position: relative;
		width: 100vw;
		height: 100vh;
	`
}

const App = () => {

	return <>
		<S.Background src={korshem} />
		<S.App className="flex-column">
			<Header />
			<div className="flex-column">
				<AppRoutes />
			</div>
		</S.App>
	</>

}

export default App;