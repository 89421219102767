import DropdownList from "./DropdownList";
import CardDropdownItem from "./CardDropdownItem";
import styled from "styled-components";

const S = {
	DropdownList: styled(DropdownList)`
		min-width: 240px;
	`
}

const CardDropdown = (props) => {
	const { onChange, closeOnClick=true } = props;

	return <S.DropdownList
		{...props}
	>
		{
			(card, index, { onClose, isHighlighted }) => <CardDropdownItem
				key={card?.id}
				card={card}
				isHighlighted={isHighlighted}
				onClick={evt => {
					if(closeOnClick){
						evt.preventDefault();
						evt.stopPropagation();
					}
					if(onChange){
						onChange(card);
					}
					if(closeOnClick && onClose){
						onClose(evt);
					}
				}}
			/>
		}
	</S.DropdownList>

}

export default CardDropdown;