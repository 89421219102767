import { Modal, Button, Input } from "./../components";
import useSingleton from "./../hooks/useSingleton";
import { useCallback, useState, useEffect } from "react";
import useSharedState, { useSetSharedState } from "./../hooks/useSharedState";
import useStable from "./../hooks/useStable";
import { duplicateDeck } from "./../hooks/useDatabase";
import useLoader from "./useLoader";

const useDuplicateDeck = () => {

	useSingleton("duplicate-deck", useCallback(() => <DuplicateDeckWrapper />, []));

	const setConfig = useSetSharedState("duplicate-deck");
	return useCallback(async config => {
		return new Promise(resolve => {
			setConfig(Object.assign({}, config, {
				onComplete: deckId => {
					setConfig(null);
					resolve(deckId);
				},
				onCancel: () => {
					setConfig(null);
					resolve(null);
				}
			}))
		})
	}, [ setConfig ]);

}


const DuplicateDeckWrapper = () => {

	const [ config ] = useSharedState("duplicate-deck");
	const stableConfig = useStable(config);

	return <Modal
		open={!!config}
		onClose={config?.onCancel}
		allowBackgroundClose
		allowEscapeClose
	>
		<DuplicateDeck { ...(stableConfig || {}) }  />
	</Modal>

}

const DuplicateDeck = ({onComplete, onCancel, deck, boards}) => {

	const [ name, setName ] = useState("")

	const load = useLoader();

	useEffect(() => {
		setName(`${deck?.name} (copy)`);
	}, [ deck ]);

	return <>
		<h2>Duplicate Deck</h2>
		<Input value={name} onChange={setName} />
		<div className="space-24" />
		<div className="flex-container-centered">
			<Button
				label="Duplicate"
				onClick={async () => {
					const deckId = await load(duplicateDeck(deck, boards, { name }), {
						title: "Duplicating deck...",
					});
					onComplete(deckId);
				}}
			/>
			<div className="space-12" />
			<Button
				label="Cancel"
				onClick={onCancel}
			/>
		</div>
	</>

}



export default useDuplicateDeck;