import { useState, useEffect, useCallback, useRef } from "react";
import Callbacks from "./../utils/Callbacks";

const callbacks = new Callbacks();

const sharedStateValues = {};

export const setSharedState = (key, value, preventDispatch) => {
	if(typeof(value) === "function"){
		sharedStateValues[key] = value(getSharedState(key));
	} else {
		sharedStateValues[key] = value;	
	}
	if(!preventDispatch){
		callbacks.dispatch(key);
	}
}

export const useSetSharedState = key => useCallback((value) => setSharedState(key, value), [key]);

export const getSharedState = (key) => sharedStateValues[key];

const initialisedSharedState = {};

// Be careful with defaultValue across different components -- there will
// only be one initialisation for whoever gets there first.
const useSharedState = (key, defaultValue, debugName) => {

	const [, rerender] = useState()

	// We have to store this and check for new data at the time of subscription.
	// It is possible for a callback to be fired before subscription occurs.
	const initiallyRenderedValueRef = useRef(sharedStateValues[key]);

	if(!initialisedSharedState[key]){
		if(sharedStateValues[key] === undefined){
			if(debugName){
				console.log(`useSharedState defaultValue set for ${debugName}`, key, defaultValue);
			}
			initialisedSharedState[key] = true;
			setSharedState(key, defaultValue, true);	
		} else {
			if(debugName){
				console.log(`useSharedState defaultValue for ${debugName} ignored, a value is already present.`, {key, defaultValue, existing: sharedStateValues[key]});
			}
		}
	}

	const setValue = useCallback(value => {
		if(debugName){
			console.log(`useSharedState setValue called for ${debugName}`, key, value);
		}
		setSharedState(key, value);
	}, [ key, debugName ])

	useEffect(() => {

		if(debugName){
			console.log(`useSharedState creating subscription for ${debugName}`, key);
		}

		const unsub = callbacks.on(key, () => rerender({}));

		// We force a rerender in the rare case that a change has occurred before we had
		// a chance to subscribe.
		if(sharedStateValues[key] !== initiallyRenderedValueRef.current){
			if(debugName){
				console.log(`useSharedState forces a rerender on subscription for ${debugName}`, key);
			}
			rerender({});
		}

		return unsub;
	}, [ key, debugName ])
	
	return [ sharedStateValues[key], setValue ];

}

export default useSharedState;