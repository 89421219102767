import { ResizeHandle } from "./../components";
import styled from "styled-components/macro";
import useBounds from "./../hooks/useBounds";

const S = {
	Container: styled.div`
		display: flex;
		flexDirection: row;
		position: relative;
	`,
	Left: styled.div`
		width: ${ ({rightPanelAmount}) => (1 - rightPanelAmount) * 100 }%;
		height: 100%;
		position: relative;
		flex-shrink: 0;
	`,
	HandleContainer: styled.div`
		position: relative;
		height: 100%;
		// background-color: red;
		// width: 2px;
	`,
	HorzHandle: styled(ResizeHandle)`
		position: absolute;
		top: 0px;
		height: 100%;
		transform: translateX(${({handleOffset}) => handleOffset}px);
		z-index: 4;
	`,
	Right: styled.div`
		flex: 1;
		position: relative;
		overflow: hidden;
	`,
}

const HorizontalPanels = ({
	className,
	rightPanelAmount,
	onChange,
	maxRightPanelAmount,
	minRightPanelAmount,
	left,
	right,
	handleOffset=6,
	disableHandle
}) => {

	const [ containerBounds, containerRef ] = useBounds();

	return <S.Container ref={containerRef} className={className}>
		<S.Left rightPanelAmount={Math.floor(rightPanelAmount * 100) * 0.01}>
			{ left }
		</S.Left>
		<S.HandleContainer>
			{
				disableHandle ? null : <S.HorzHandle
					value={rightPanelAmount * containerBounds.width}
					onChange={val => onChange(Math.max(Math.min(val / containerBounds.width, maxRightPanelAmount)), minRightPanelAmount)}
				/>
			}
		</S.HandleContainer>
		<S.Right>
			{ right }
		</S.Right>
	</S.Container>

}

export default HorizontalPanels;