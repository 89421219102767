import useSharedState, { useSetSharedState } from "./../hooks/useSharedState";
import { Fragment, useEffect } from "react";

const useSingleton = (name, getComponent, singletonManagerId="default") => {

	const setSingletons = useSetSharedState(`singletons-${singletonManagerId}`);

	useEffect(() => {
		if(name && getComponent){
			setSingletons(existing => {
				if(!existing || !existing[name] || existing[name] !== getComponent){
					return Object.assign({}, existing, {
						[name]: getComponent
					});
				} else {
					return existing;
				}
			});
		}
	}, [ name, getComponent, setSingletons ]);

}

export const SingletonManager = ({id="default"}) => {

	const [ singletons ] = useSharedState(`singletons-${id}`);

	return singletons ? Object.values(singletons).map((s, i) => <Fragment key={i}>{s()}</Fragment>) : null

}

export default useSingleton;