import styled from "styled-components";
import { Button, Card, Input } from "./../components";
import { useState, useEffect } from "react";
import { useCard, deleteDeck, renameDeck, useUser } from './../hooks/useDatabase';
import formats from './../data/formats';
import { useDuplicateDeck, useConfirm } from "./../singleton-hooks"
import { useNavigate } from "react-router-dom";
import { getBoardsUniqueCards, getBoardsMaxCardCounts, sortCards, getWeapons,
		getEquipment, getNormalCards } from "./../utils/DeckUtils";
import { copyToClipboard } from './../utils/JSUtils';
import { pitchNames } from './../data/pitch';

const S = {
	Root: styled.div`
		width: 300px;
		position: relative;
		height: 100%;
		display: flex;
		flex-direction: column;
	`,
	BackgroundCard: styled(Card)`
		position: absolute;
		top: 0px;
		left: 0px;
		width: 100%;
		border-radius: 0px;
	`,
	Container: styled.div`
		margin-top: 20px;
		padding: 150px 24px 0px 24px;
		position: relative;
		background: linear-gradient(rgba(43,43,43,0) 0%, rgba(43,43,43,1) 250px);
		flex: 1;
	`,
	InfoContainer: styled.div`
		border: 1px solid var(--color-text);
		padding: 3px 6px;
		border-radius: 3px;
	`
}

const DeckDetails = ({deck, boards, stats}) => {

	const [ stagingName, setStagingName ] = useState(deck?.name);
	const [ owner ] = useUser(deck?.ownerId);
	const getDuplicatedDeck = useDuplicateDeck();
	const navigate = useNavigate();
	const confirm = useConfirm();

	const [ hero ] = useCard(deck?.heroId);

	useEffect(() => {
		setStagingName(deck?.name)
	}, [ deck?.name ])

	return <S.Root>
		<S.BackgroundCard
			card={hero}
			width={300}
			dimensions="framed"
		/>
		<S.Container>
			<div className="flex-container-centered">
				<Input
					className="white simple h2 align-left"
					value={stagingName}
					onChange={setStagingName}
					blurBehaviour="submit"
					onSubmit={() => renameDeck(deck?.id, stagingName)}
				/>
				<div className="flex" />
				<Button
					icon="delete"
					onClick={async () => {
						const confirmed = await confirm({
							title: "Delete deck?",
							description: "This cannot be undone.",
							confirm: "Delete",
							confirmClassName: "warn",
						})
						if(confirmed){
							navigate(`/`);
							await deleteDeck(deck, boards, stats);
						}
					}}
				/>
				<div className="space-6" />
				<Button
					icon="duplicate"
					onClick={async () => {
						const deckId = await getDuplicatedDeck({deck, boards});
						console.log("Deck id", deckId);
						if(deckId){
							navigate(`/deck/${deckId}`);
						}
					}}
				/>
				<div className="space-6" />
			</div>
			<p className="margin-0">By {owner?.name}</p>
			<div className="space-24" />
			<div className="flex-container-centered">
				<S.InfoContainer>
					<p className="margin-0 font-size-small">{formats[deck?.format]?.label}</p>
				</S.InfoContainer>
				<div className="space-24" />
				<S.InfoContainer>
					<p className="margin-0 font-size-small">Private</p>
				</S.InfoContainer>
			</div>
			<Button
				label="Export to clipboard"
				onClick={() => {
					const cardMap = getBoardsUniqueCards(boards, true);
					const maxCounts = getBoardsMaxCardCounts(boards);
					let s = `${deck.hero.name}`;
					const cards = sortCards(Object.values(cardMap), "pitch-alpha");
					const weapons = getWeapons(cards);
					const equipment = getEquipment(cards);
					const normal = getNormalCards(cards);
					s += `\nWeapons: ${weapons.map(w => w.name).join(", ")}`;
					s += `\nEquipment: ${equipment.map(e => e.name).join(", ")}`;
					s += "\n\n";
					for(let i = 0; i < normal.length; i++){
						const c = normal[i]
						s += `[${maxCounts[c.id]}] ${c.name}${c.pitch ? ` (${pitchNames[c.pitch]})` : ""}`
						if(i !== normal.length - 1){
							s += "\n";
						}
					}
					copyToClipboard(s);
				}}
			/>
			<p>Description down here</p>
		</S.Container>
	</S.Root>
}

export default DeckDetails;