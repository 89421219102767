import { useState, useEffect, useMemo, useCallback } from 'react';
import firebase from './../data/firebase';
import useAuth from "./useAuth";
import * as fs from "firebase/firestore";
import { getSharedState } from "./../hooks/useSharedState";
import { getBoardsUniqueCards } from "./../utils/DeckUtils";

const db = fs.getFirestore(firebase);

const algoliasearch = require('algoliasearch')
const client = algoliasearch('9UDBNTOXQT', '86d3be6f0e9fee8a904c1e5b1683c3c8');
const index = client.initIndex('cards');

const parseSearch = res => res.hits;
const parseObjects = res => res.results;
const parseNone = res => res;
const parseDoc = res => res.data();

export const newBoard = {
	name: "",
	cards: [],
	createdAt: fs.serverTimestamp(),
}

const EMPTY_ARRAY = [];

const usePromise = (promise, parseResults, defaultValue=EMPTY_ARRAY) => {
	const [ results, setResults ] = useState(EMPTY_ARRAY);
	const [ error, setError ] = useState(null);
	const [ loading, setLoading ] = useState(false);

	useEffect(() => {
		let cancelled = false;

		if(promise){
			setLoading(true);
			setError(null);
			promise()
				.then((results) => {
					setResults(parseResults(results));
				})
				.catch(error => {
					console.warn("Error!", error);
					if(!cancelled){
						setError(error);
					}
				}).finally(() => {
					setLoading(false);
				})
		}

		return () => {
			cancelled = true;
		}
	}, [ promise, parseResults ])

	return [ results, loading, error ];
}

export const useCore = core => usePromise(
	useCallback(() => fs.getDoc(fs.doc(db, "core", core)), [ core ]),
	parseDoc
);

const cardCache = {};

export const useCardSearch = (term, filters) => {
	const [ results, loading, error ] = usePromise(
		useMemo(() => term?.length || filters ? () => index.search(term, {filters, page: 0, hitsPerPage: 200}) : null, [ term, filters ]),
		parseSearch
	);

	useEffect(() => {
		results?.forEach(r => cardCache[r.id] = r);
	}, [ results ]);

	return [ results, loading, error ];
}

export const useCards = cardIds => {

	const missingCards = useMemo(() => cardIds?.filter(cid => !cardCache[cid]), [cardIds]);

	const [ cards, loading, error ] = usePromise(
		useMemo(() => missingCards?.length ? () => index.getObjects(missingCards) : null, [missingCards]),
		parseObjects
	);

	useEffect(() => {
		if(!cards?.length){
			return;
		}
		for(let i = 0; i<cards.length; i++){
			if(!cardCache[cards[i].id]){
				cardCache[cards[i].id] = cards[i];
			}
		}
	}, [ cards ]);

	return [
		useMemo(() => cardIds?.map(cid => cardCache[cid] || cards?.find(c => c.id === cid)).filter(c => !!c) || EMPTY_ARRAY, [ cardIds, cards ]),
		loading,
		error
	]
}

export const useCard = cardId => {
	const results = usePromise(
		useMemo(() => cardId ? () => index.getObject(cardId) : null, [cardId]),
		parseNone,
		null
	);
	return results
}

const useSnapshot = (location, handleAs) => {
	const [ data, setData ] = useState(null);
	const [ loading, setLoading ] = useState(false);
	const [ error, setError ] = useState(null);
	useEffect(() => {
		if(location){
			setLoading(true);
			return fs.onSnapshot(location, snap => {
				setLoading(false);
				const dataFromDoc = doc => Object.assign({}, doc.data(), { id: doc.id });
				switch(handleAs){
					case "array":
						const ret = [];
						snap.forEach(doc => doc ? ret.push(dataFromDoc(doc)) : null);
						setData(ret);
					break;
					default:
						setData(snap.exists() ? dataFromDoc(snap) : null);
					break;
				}
			}, err => {
				setError(err);
				setLoading(false);
			})
		}
	}, [ location, handleAs ]);

	return [ data, loading, error ];
}

export const useDecks = () => {
	const user = useAuth();

	return useSnapshot(
		useMemo(() => 
			user?.uid ? fs.query(fs.collection(db, "decks"), fs.where("ownerId", "==", user.uid))
				: null,
			[ user ]
		),
		"array"
	)
}

const useCardHydrationMap = cardIds => {
	const [ cards, loading, error ] = useCards(cardIds);
	const hydrationMap = useMemo(() => {
		const ret = {};
		cards.forEach(c => ret[c.id] = c);
		return ret;
	}, [ cards ]);
	return [ hydrationMap, loading, error ];
}

export const useHydratedDeck = deckId => {
	const [ deck, loadingDeck, deckError ] = useSnapshot(
		useMemo(() => fs.doc(db, "decks", deckId), [ deckId ])
	);
	const toHydrate = useMemo(() => deck?.heroId ? [deck.heroId] : null, [ deck ]);
	const [ hydrationMap, loadingHydrationMap, hydrationError ] = useCardHydrationMap(toHydrate);

	const hydrated = useMemo(() => {
		if(!deck || !hydrationMap){
			return null
		}
		return Object.assign({},
			deck,
			{
				hero: hydrationMap[deck.heroId]
			},
		);
	}, [ deck, hydrationMap ]);

	return [ hydrated, loadingDeck || loadingHydrationMap, deckError || hydrationError ];
}


const dehydrateCard = card => ({id: card.id, count: card.count});

export const useHydratedDeckBoards = deckId => {
	const [ boards, loadingBoards, boardsError ] = useSnapshot(
		useMemo(() => fs.collection(db, "decks", deckId, "boards"), [ deckId ]),
		"array"
	);
	const toHydrate = useMemo(() => getBoardsUniqueCards(boards), [ boards ]);
	const [ hydrationMap, loadingHydrationMap, hydrationError ] = useCardHydrationMap(toHydrate);

	const hydrated = useMemo(() => {
		if(!boards?.length || !hydrationMap){
			return EMPTY_ARRAY;
		}
		return boards.map(b => Object.assign({}, b, {
			cards: b.cards.map(c => Object.assign({}, c, hydrationMap[c.id])).filter(c => !!c),
		}));
	}, [ boards, hydrationMap ]);

	return [ hydrated, loadingBoards || loadingHydrationMap, boardsError || hydrationError ];
}

export const useDeckStats = deckId => useSnapshot(
	useMemo(() => fs.collection(db, "decks", deckId, "stats"), [ deckId ]),
	"array",
);

export const addStat = (deck, stat) => {
	return fs.addDoc(fs.collection(db, "decks", deck?.id, "stats"), stat);
}

export const removeStat = (deck, stat) => {
	return fs.deleteDoc(fs.doc(db, "decks", deck?.id, "stats", stat.id))
}

export const editStat = (deck, stat, updates) => {
	return fs.updateDoc(fs.doc(db, "decks", deck?.id, "stats", stat.id), updates);
}

export const createDeck = async ({name, format, heroId}) => {

	const user = getSharedState("auth");

	if(!user){
		throw new Error("Not signed in");
	}

	const newDeckDoc = fs.doc(fs.collection(db, "decks"));

	const batch = fs.writeBatch(db);
	batch.set(fs.doc(db, "decks", newDeckDoc.id), {
		name,
		format,
		heroId,
		ownerId: user.uid,
		createdAt: fs.serverTimestamp(),
	});
	const newBoardDoc = fs.doc(fs.collection(db, "decks", newDeckDoc.id, "boards"));
	batch.set(
		fs.doc(db, "decks", newDeckDoc.id, "boards", newBoardDoc.id),
		Object.assign({}, newBoard, {
			name: "Main",
		})
	);

	await batch.commit();

	return newDeckDoc.id;
}

export const setCardTotal = async (deck, board, cardId, count, options) => {

	if(!deck || !board){
		throw new Error("Invalid board");
	}

	const cards = board.cards || [];

	const cardIndex = cards.findIndex(c => c.id === cardId);

	const newEntry = { id: cardId, count };

	const updates = {
		cards: !options?.allowGhosting && count === 0 ? cards.filter(c => c.id !== cardId)
			: cardIndex === -1 ? [ ...cards, newEntry ] : [
				...cards.slice(0, cardIndex).map(dehydrateCard),
				newEntry,
				...cards.slice(cardIndex + 1).map(dehydrateCard),
			]
	}

	return fs.updateDoc(fs.doc(db, "decks", deck.id, "boards", board.id), updates);

}

export const duplicateDeck = async (deck, boards, updates) => {

	const user = getSharedState("auth");

	if(!user){
		throw new Error("Not signed in");
	}

	if(!deck){
		throw new Error("No deck");
	}

	const batch = fs.writeBatch(db);

	const newDeckDoc = fs.doc(fs.collection(db, "decks"));
	const newDeck = Object.assign({}, deck, {
		name: `${deck.name} (copy)`,
		ownerId: user.uid,
		parentId: deck.id,
		createdAt: fs.serverTimestamp(),
	}, updates)
	delete(newDeck.id);
	delete(newDeck.hero);
	batch.set(fs.doc(db, "decks", newDeckDoc.id), newDeck);
	for(let i = 0; i<boards.length; i++){
		const newBoardDoc = fs.doc(fs.collection(db, "decks", newDeckDoc.id, "boards"));
		const newBoard = Object.assign({}, boards[i], {
			// Don't change createdAt -- it'll mess up the natural ordering
			// createdAt: fs.serverTimestamp(),
		});
		delete(newBoard.id);
		batch.set(fs.doc(db, "decks", newDeckDoc.id, "boards", newBoardDoc.id), newBoard);
	}
	await batch.commit();

	return newDeckDoc.id;
}

export const renameBoard = async (deck, board, name) => {
	return fs.updateDoc(fs.doc(db, "decks", deck.id, "boards", board.id), { name });
}

export const updateTrackedBoardId = async (deck, board, trackedBoardId) => {
	return fs.updateDoc(fs.doc(db, "decks", deck.id, "boards", board.id), {
		trackedBoardId: trackedBoardId || fs.deleteField(),
	});
}

export const addBoard = async (deck, board) => {
	const user = getSharedState("auth");
	if(!user){
		throw new Error("Not signed in");
	}

	if(!deck || !board){
		throw new Error("Invalid board");
	}

	const newBoard = Object.assign({}, board, {
		createdAt: fs.serverTimestamp(),
	})
	delete(newBoard.id);

	const b = await fs.addDoc(fs.collection(db, "decks", deck.id, "boards"), newBoard);
	return b?.id;
}

export const removeBoard = async (deck, board) => {
	const user = getSharedState("auth");
	if(!user){
		throw new Error("Not signed in");
	}

	if(!deck || !board){
		throw new Error("Invalid board");
	}

	return fs.deleteDoc(fs.doc(db, "decks", deck.id, "boards", board?.id));
}

const updateDeck = (deckId, updates) => {
	return fs.updateDoc(fs.doc(db, "decks", deckId), updates);
}

export const renameDeck = (deckId, name) => updateDeck(deckId, { name });

export const useUser = userId => useSnapshot(
	useMemo(() => userId ? fs.doc(db, "users", userId) : null, [ userId ])
);

export const deleteDeck = async (deck, boards, stats) => {
	const batch = fs.writeBatch(db);

	batch.delete(fs.doc(db, "decks", deck.id));
	boards.forEach(b => batch.delete(fs.doc(db, "decks", deck.id, "boards", b.id)));
	stats.forEach(s => batch.delete(fs.doc(db, "decks", deck.id, "stats", s.id)));
	await batch.commit();
}

