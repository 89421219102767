import useAuth, { signIn, signOut } from "./../hooks/useAuth";
import { useUser, useCard } from "./../hooks/useDatabase";
import { useCallback, useState } from "react";
import { LoadWheel, Callout, Button, Input, Popup, CardImage, StandardDropdownList } from "./../components";
import styled from "styled-components";

const S = {
	Popup: styled(Popup)`
		width: 300px;
		padding: 12px;
	`,
	AvatarBorder: styled.div`
		padding: 2px;
		border-radius: 24px;
		background: var(--gradient-gold);
		width: fit-content;
	`,
	AvatarCard: styled(CardImage)`
		border-radius: 18px;
		border: 2px;
		overflow: hidden;
	`,
	UserEntryContainer: styled.div`
		border-radius: 2px;
		cursor: pointer;
		&:hover {
			opacity: 0.9;
		}
	`
}

const getErrorString = error => {
	if(error.message.includes("auth/wrong-password")){
		return "The password or email were incorrect";
	}
	return "An unknown error occurred"
}

const UserEntry = ({userId}) => {

	const [ details ] = useUser(userId);
	const [ card ] = useCard(details?.avatar);
	const [ open, setOpen ] = useState(false);

	return  <div className="relative">
		<S.UserEntryContainer
			className="flex-container-centered"
			onClick={open ? null : () => setOpen(true)}
		>
			<h4>{details?.name}</h4>
			<div className="space-12" />
			<S.AvatarBorder>
				<S.AvatarCard
					width={30}
					card={card}
				/>
			</S.AvatarBorder>
		</S.UserEntryContainer>
		<StandardDropdownList
			open={open}
			onClose={() => setOpen(false)}
			options={{
				signOut: {
					label: "Sign Out",
					icon: "sign-out",
					onClick: () => signOut(),
				},
				profile: {
					label: "My Account",
					icon: "profile",
					onClick: () => console.log("Open profile!"),
				}
			}}
		/>
	</div>
}

const Auth = () => {

	const user = useAuth();

	const [ open, setOpen ] = useState(false);
	const [ email, setEmail ] = useState("");
	const [ password, setPassword ] = useState("");
	const [ error, setError ] = useState(null);
	const [ signingIn, setSigningIn ] = useState(false);

	const handleSignIn = useCallback(() => {
		setError(null);
		setSigningIn(true);
		signIn(email, password)
		.then(() => console.log("Signed in"))
		.catch(error => setError(error))
		.finally(() => setSigningIn(false))
	}, [ email, password ]);

	return <div className="relative">
		{
			user ? <UserEntry userId={user?.uid} /> : <>
				<Button
					label="Sign in"
					onClick={ () => setOpen(true)}
				/>
				<S.Popup
					open={open}
					onClose={() => setOpen(false)}
					pointerEvents
				>
					<Input
						type="email"
						value={email}
						onChange={setEmail}
						placeholder="Email"
					/>
					<div className="space-6" />
					<Input
						type="password"
						value={password}
						onChange={setPassword}
						onSubmit={handleSignIn}
						placeholder="Password"
					/>
					{
						signingIn ? <LoadWheel /> : <>
							<Button
								label="Sign in"
								onClick={handleSignIn}
							/>
							{
								error ? <Callout className="error">
									<p>{ getErrorString(error) }</p>
								</Callout> : null
							}
						</>
					}
					
				</S.Popup>
			</>
		}
	</div>

}

export default Auth;