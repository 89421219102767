import Spinner from "react-spinkit"
import styled from "styled-components/macro";

const S = {
	Container: styled.div`
		text-align: center;
		display: block;
		margin: 12px 6px;
	`,
	Spinner: styled(Spinner)`
		display: inline-block;
	`
}

const LoadWheel = ({className, style, delay, color="white"}) => <S.Container className={className}>
	<S.Spinner fadeIn={delay ? null : "none"} name="circle" color={color} />
</S.Container>

export default LoadWheel
