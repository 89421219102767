import styled, { css } from "styled-components";
import CardImage from "./CardImage";
import { pitchColors } from "./../AppStyles";

const S = {
	Container: styled.div`
		display: flex;
		align-items: center;
		cursor: pointer;
		&:hover {
			background-color: var(--color-selection-dark);
		}

		${({isHighlighted}) => isHighlighted && css`
			background-color: var(--color-selection);
		`}
		${({color}) => css`
			border-left: 4px solid ${color ? `var(${color})` : "transparent"};
		`}
	`,
	CardImage: styled(CardImage)`
		flex-shrink: 0;
	`,
}

const CardDropdownItem = ({className, card, onClick, isHighlighted}) => {
	return <S.Container
		className={className}
		onMouseDown={onClick}
		isHighlighted={isHighlighted}
		color={pitchColors[card?.pitch - 1]}
	>
		<S.CardImage card={card} width={40} />
		<div className="space-12" />
		<p className="margin-6-0 ellipsis semibold">{card?.name}</p>
	</S.Container>
}

export default CardDropdownItem