import DropdownList from "./DropdownList";
import StandardDropdownOption from "./StandardDropdownOption";

const StandardDropdownList = props => {
	const { options, onClick, onClose, closeOnClick, selected } = props;

	return <DropdownList
		{...props}
		options={Object.keys(options)}
	>
		{
			(op, i) => {
				return <StandardDropdownOption
					key={i}
					{...options[op]}
					selected={selected && selected === op}
					onMouseDown={evt => {
						if(closeOnClick){
							evt.preventDefault();
							evt.stopPropagation();
						}
						if(op.onClick){
							op.onClick(evt);
						}
						if(onClick){
							onClick(op, evt)
						}
						if(closeOnClick && onClose){
							onClose(evt);
						}
					}}
				/>
			}
		}
	</DropdownList>
}

export default StandardDropdownList;