import { Routes, Route } from "react-router-dom";
import { Home, Deck, Page404 } from './views';

const AppRoutes = () => {

	return <Routes>
		<Route path="deck">
			<Route path=":deckId" element={<Deck />} />
		</Route>
		<Route path="/" element={<Home />} />
		<Route path="*" element={<Page404 />} />
	</Routes>

}

export default AppRoutes;