import { memo, useEffect, useState, useRef } from "react"
import classnames from "classnames";
import styled from "styled-components/macro";

const S = {
	Container: styled.div`
		@keyframes shimmer {
			0% {
				transform: translateX(-110%);
			}
			100% {
				transform: translateX(110%)
			}
		}

		background-color: ${({backgroundColor}) => backgroundColor};

		opacity: 0;
		will-change: opacity;
		transition: opacity 300ms 100ms;
		width: 100%;
		position: relative;
		// overflow: hidden;
		pointer-events: none;
		height: 0px;

		&.open {
			opacity: 1;
			transition: opacity 300ms;
		}

		&.expand {
			position: absolute;
			top: 0px;
			left: 0px;
			width: 100%;
			height: 100%;
		}

		&.slim {
			bottom: 2px;
			height: 2px;
			border-radius: 1px;
			width: 100%;
			background-color: var(--color-cta);
		}

		&.rounded {
			border-radius: 2px;
		}
	`,
	Color: styled.div`
		animation-duration: 2s;
		animation-iteration-count: infinite;
		animation-name: shimmer;
		backface-visibility: hidden;
		background-color: transparent;
		will-change: transform;
		width: 100%;
		height: 100%;
		background-image: ${({shimmerRgb, shimmerRgb2}) => `linear-gradient(to right, rgba(${shimmerRgb}, 0) 0%, rgba(${shimmerRgb2}, 0.3) 50%, rgba(${shimmerRgb}, 0) 100%)`};
	`
}

const LoadDiv = memo(({
	className,
	style,
	expand,
	children,
	open,
	delay=100,
	backgroundColor="transparent",
	shimmerRgb="255, 255, 255",
	shimmerRgb2="230, 242, 255",
	rounded
}) => {

	const styleRef = useRef({
		transform: `translateX(${Math.random() * 20}%)`
	});

	const [ entered, setEntered ] = useState(false);

	useEffect(() => {
		if(open){
			const timeout = setTimeout(() => setEntered(true), delay);
			return () => clearTimeout(timeout);
		} else {
			setEntered(false);
		}
	}, [ open, delay ])

	return <S.Container
		className={classnames(className, {open: open && entered, expand, rounded})}
		style={style}
		backgroundColor={backgroundColor}
	>
		<div className="absolute-expand" style={styleRef.current}>
			<S.Color shimmerRgb={shimmerRgb} shimmerRgb2={shimmerRgb2} />
		</div>
	</S.Container>
})

export default LoadDiv